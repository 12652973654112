import { Grid, TextField, MenuItem } from '@mui/material';
import ClientFormDropdown from './ClientFormDropdown';

const OPTIONS = [
    {id: 0, label: "No"},
    {id: 1, label: "Yes"}
]

const FormFields = ({ formData, handleChange, occupationList = [], sourceList = [],
    genderList = [], educationList = [], healthList=[]
 }) => {     
    return (
        <>
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="client name"
                    name="clientName"
                    value={formData.clientName}
                    onChange={handleChange}
                    required
                    size="small"
                    disabled
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="primary contact"
                    name="primaryContact"
                    type="tel"
                    value={formData.primaryContact}
                    onChange={handleChange}
                    inputProps={{ pattern: '[0-9]{10}', title: 'Please enter a 10-digit phone number' }}
                    required
                    size="small"
                    disabled
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="alternate contact"
                    name="alternateContact"
                    value={formData.alternateContact}
                    onChange={handleChange}
                    inputProps={{ pattern: '[0-9]{10}', title: 'Please enter a 10-digit phone number' }}
                    size="small"
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="age"
                    name="age"
                    value={formData.age}
                    onChange={handleChange}
                    size="small"
                    type="number"
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    select
                    label="gender" 
                    name="gender"  
                    size="small"  
                    onChange={handleChange} 
                    value={formData.gender}
                    disabled
                >
                    {genderList.map((gender) => (
                        <MenuItem key={gender.id} value={gender.id}>
                            {gender.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="city"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    required
                    size="small"
                    disabled
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    select
                    label="Education"   
                    name='education'
                    size="small"  
                    onChange={handleChange} 
                    value={formData.education}
                >
                    {educationList.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>

            <Grid item xs={12} md={6}>
                {
                    formData.education == 4 && (
                        <TextField
                            fullWidth
                            label="specific education"
                            name="educationtxt"
                            value={formData.educationtxt || ""}
                            onChange={handleChange}
                            size="small"
                        />
                    )
                }
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    select
                    label="occupation"   
                    name='occupation'
                    size="small"  
                    onChange={handleChange} 
                    value={formData.occupation}
                    required   
                >
                    {occupationList.map((occupation) => (
                        <MenuItem key={occupation.id} value={occupation.id}>
                            {occupation.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="height (cm)"
                    name="height"
                    value={formData.height}
                    onChange={handleChange}
                    size="small"
                    type="number"
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="weight (kg)"
                    name="weight"
                    value={formData.weight}
                    onChange={handleChange}
                    size="small"
                    type="number"
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    select
                    label="how did you learn about avataar?"   
                    size="small"  
                    name='source'
                    onChange={handleChange} 
                    value={formData.source}
                >
                    {sourceList.map((source) => (
                        <MenuItem key={source.id} value={source.id}>
                            {source.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    select
                    label="On boarded?"   
                    size="small"  
                    name='onboarded'
                    onChange={handleChange} 
                    value={formData.onboarded || 0}
                >
                    {OPTIONS.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>

            <Grid item xs={12} md={6}/>

            <Grid item xs={12} md={6}>
                <ClientFormDropdown 
                    handleChange={handleChange}
                    fieldname="health"
                    value={formData.health}
                    list={healthList}
                />  
            </Grid>

            <Grid item xs={12} md={6}>
                {
                    formData.health?.some(item => item.toLowerCase().includes("other")) && (
                        <TextField
                        fullWidth
                        label="Specify other health conditions"
                        name="healthtxt"
                        value={formData.healthtxt}
                        onChange={handleChange}
                        size="small"
                        />
                    )
                }
            </Grid>

            <Grid item xs={12}>
                <p>Current Skincare Products</p>
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="faceWash"
                    name="faceWash"
                    value={formData.faceWash}
                    onChange={handleChange}
                    size="small"
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="moisturizer"
                    name="moisturizer"
                    value={formData.moisturizer}
                    onChange={handleChange}
                    size="small"
                />
            </Grid>
            
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="sunscreen"
                    name="sunscreen"
                    value={formData.sunscreen}
                    onChange={handleChange}
                    size="small"
                />
            </Grid>
            
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="serum"
                    name="serum"
                    value={formData.serum}
                    onChange={handleChange}
                    size="small"
                />
            </Grid>
        </>
    );
};

export default FormFields;
