import './Login.style.css';
import logoImage from '../../../src/assets/images/avataar_logo_black.png';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchDoctorLogin } from '../../store/actions/loginAction';

const SignIn = ()=>{
    const [user, setUser] = useState({
        phone: '',
    });
    const navigate = useNavigate();
    
    useEffect(()=>{
      const doctorData = JSON.parse(localStorage.getItem("doctorData"))

      if(doctorData?.status?.code === 200){
        navigate('/doctor/appointment');  
      }else{
        navigate('/doctor/login');  
      }   
    }, [])
    
    const checkAndSetValidationsErrors = () => {
      let hasError = false;
      try {
        const validations = {
          enterPhone: "Please enter phone",
          invalidPhone: "Please enter valid phone number"
        };
        const phoneRegex = /^\+?[1-9]\d{1,14}$/;
        Object.keys(user).forEach((field) => {          
          if(field == "phone"){            
            if (!user[field]) {
              window.alert(validations["enterPhone"]);
              hasError = true;
            }
            else if(!phoneRegex.test(user[field])){  
              window.alert(validations["invalidPhone"])  
              hasError = true;
            }
          }
        });
      } catch (error) {
        console.error(error);       
      }
      return hasError;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUser({
          ...user,
          [name]: value,
        });
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        if(!checkAndSetValidationsErrors()){ 
        
          const respose = await fetchDoctorLogin(user.phone)     
          if (respose?.status?.code === 200) {
            localStorage.setItem('doctorData', JSON.stringify(respose));
            navigate('/doctor/appointment');  
          }
          else{
            window.alert(respose?.status?.message)
          }
        }
      } catch (error) {
        window.alert("Something went wrong")
      }
    };

    return(
        <div className="login-container">
        <div className="image-form-container">
          <img src={logoImage} alt="avataar_logo" className="logo-image" />
  
          <form className="login-form" onSubmit={handleSubmit}>
            <div className="input-container">
              <label htmlFor="phone">Phone</label>
  
              <input
                name="phone"
                placeholder="Enter your Phone"
                required
                maxLength={10}
                value={user.phone}
                onChange={handleChange}
              />
            </div>
    
            <button type="submit" className="login-button">
              Log In
            </button>
          </form>
        </div>
      </div>
    )
}

export default SignIn