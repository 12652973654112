import axios from "axios";
import { useEffect, useState } from "react"
import { getToken } from "../../components/common/userLocalStorageUtils";

import {
    Box,
    Typography,
    CircularProgress,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    Paper,
    TableCell,
    TableBody,
    Grid,
    TextField,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    IconButton
  } from '@mui/material';
import { Search } from "@mui/icons-material";


const commentTypeOptions = [
    { value: '', label: 'All' },
    { value: 'PLACE ORDER', label: 'PLACE ORDER' },
    { value: 'ADD TO CART', label: 'ADD TO CART' },
    { value: 'LOGIN', label: 'LOGIN' },
    { value: 'ADD ADDRESS', label: 'ADD ADDRESS' },
];

const CustomerSupport = () =>{
    const [logData, setLogData] = useState({
        logs: [],
        length: "",
        isLoading: false
    })
    const [filteredList, setFilteredList] = useState([]);
    const [commentType, setCommentType] = useState("");
    const [search, setSearch] = useState({
        type: "",
        text: "",
        searchClick: false
    })

    const handleChange = (name, value) => {
        setLogData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      };

    const fetchCustomerSupport = async () =>{        
        handleChange("isLoading", true)
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/v1/admin/client/cs-logs`,
                {
                  headers: { token: getToken() },
                },
            );
            const data = response.data;
            
            if(data?.status?.code == 200){                
                setLogData((prevState) => ({
                    ...prevState,
                    "logs": data.list[0],
                    "length": data.list[0].length,
                    "isLoading": true,
                }));
            }
        } catch (error) {    
        } finally {
            setLogData((prevState) => ({
                ...prevState,
                "isLoading": false
            }));
        }
    }

    useEffect(()=>{
        fetchCustomerSupport();
    },[])

    useEffect(()=>{
        const handler = setTimeout(() => {
            const filteredItems = !commentType
            ? logData.logs
            : logData.logs.filter
                (
                    (item) =>
                    String(item.type).toLowerCase() === String(commentType).toLowerCase()
                );
            
            const filteredData = filteredItems.filter((item) => {
                if (search.type === "phone") {
                  return item.phone.includes(search.text);
                } else if (search.type === "name") {
                    return item.name.toLowerCase().includes(search.text.toLowerCase());
                }
                return true; // No filter applied if type is empty
            });
            setFilteredList(filteredData)
        }, 500);

        return () => {
            clearTimeout(handler); // Cleanup timeout on re-render
        };
    },[commentType, logData.logs, search.searchClick, search.text])

    return (
        <>
            <Typography variant="h4" align="center" gutterBottom>
                Customer Support
            </Typography>

            <Grid item xs={12} md={3} lg={3}>
                <SearchComponent
                    search={search}
                    setSearch={setSearch}
                />
                <TextField
                    select
                    label="Type"
                    variant="outlined"
                    value={commentType}
                    style={{ display: 'flex', justifyContent: 'flex-end', float: 'right', marginBottom: '20px', marginLeft: "10px",
                        width:"200px"
                    }}
                    size="small"
                    onChange={(e) => setCommentType(e.target.value)}
                >
                    {commentTypeOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                    ))}
                </TextField>
            </Grid>

            {
                logData.isLoading ? (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="40vh"
                    >
                        <CircularProgress />
                    </Box>
                ) 
                :(
                    <TableContainer
                        component={Paper}
                        style={{ maxWidth: '100vw', overflowX: 'auto', maxHeight: '580px' }}
                    >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Phone</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>

                            {
                                logData && logData?.length > 0 && logData?.logs 
                                && (
                                    <TableBody>
                                        {
                                            filteredList.map((row, index)=>(
                                                <TableRow key={index} style={{ fontSize: '12px' }}>
                                                    <TableCell>{row.name}</TableCell>
                                                    <TableCell>{row.phone}</TableCell>
                                                    <TableCell>{row.type}</TableCell>
                                                    <TableCell>{row.action}</TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                )
                            }
                        </Table>
                    </TableContainer>
                )
            }
        </>
    )
}

export default CustomerSupport

const SearchComponent = ({
    search,
    setSearch,
  }) => {  

    const handleSearch = () => {
        if ((search.text.trim()).length < 1) {
            return;
        }
        setSearch((prevState) =>({
            ...prevState,
            "searchClick": !prevState.searchClick
        }))
    };

    const handleChange = (event) => {
        const type = event.target.value;
        setSearch((prevState) =>({
            ...prevState,
            "text": "",
            "type": type
        }))
    };
  
    const handleSearchOnClick = (e) => {
        if ((search.text.trim()).length < 1) {
            return;
        }
        if (e.key === 'Enter') {
          e.preventDefault();  
          setSearch((prevState) =>({
            ...prevState,
            "searchClick": !prevState.searchClick
        }))
        }
    };

    const handleTextChange = (e) => {
        const text = e.target.value
        setSearch((prevState) =>({
            ...prevState,
            "text": text
        }))
    }

    return (
      <>
        <FormControl sx={{ minWidth: 200 }} size="small">
          <InputLabel id="demo-simple-select-standard-label">
            Filter Type
          </InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={search.type}
            label="searchType"
            onChange={handleChange}
          >
            <MenuItem value="name">Name</MenuItem>
            <MenuItem value="phone">Phone Number</MenuItem>
          </Select>
        </FormControl>
  
        <TextField
          placeholder="Search"
          variant="outlined"
          size="small"
          value={search.text}
          type={search.type === "phone" ? "number" : "text"}
          required={true}
          onChange={handleTextChange}
          onKeyDown={handleSearchOnClick}
          InputProps={{
            endAdornment: (
              <IconButton color="primary" onClick={handleSearch}>
                <Search />
              </IconButton>
            ),
          }}
        />
      </>
    );
  };