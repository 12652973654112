import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTherapistCustomslots, updateCustomTherapistSlot } from '../../store/actions/therapist.action';
import { useNavigate } from 'react-router-dom';
import { Button, TablePagination, Typography, TextField, InputAdornment, Tooltip, IconButton } from '@mui/material';
import { Add, Search as SearchIcon } from '@mui/icons-material';
import { Box } from '@mui/system';
import { hasAdminAndSuperAdminAccess } from '../../components/common/UserRolesConfig';
import { hasSuperAccess } from '../../utils/access';
import './RequestList.css';
import { fetchAdmin } from '../../store/actions/center.action';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RequestConfirmModal from './RequestConfirmModal';

const RequestList = () => {
    const role = JSON.parse(localStorage.getItem('userData'))?.user?.role;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [isApprovalModalOpen, setIsApprovalModalOpen] = useState(false);
    const [selectedRequestId, setSelectedRequestId] = useState(null);

    const therapistCustomSlot = useSelector(state => state?.therapist?.therapistCustomSlot?.slotDetails) || [];
    const adminList = useSelector(state => state.center?.adminList);

    useEffect(() => {
        dispatch(fetchTherapistCustomslots());
        dispatch(fetchAdmin());
    }, [dispatch]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm.toLowerCase());
        }, 300);
        return () => clearTimeout(timer);
    }, [searchTerm]);

    const filteredData = therapistCustomSlot.filter((schedule) =>
        schedule.scheduler_therapist.name.toLowerCase().includes(debouncedSearchTerm)
    );

    const paginatedData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const updateTherapistRequest = async (id, isApproved) => {
        setIsSubmitting(true);
        const localToken = JSON.parse(localStorage.getItem("userData"));
        try {
            const res = await updateCustomTherapistSlot(id, { adminId: parseInt(localToken?.user?.id), isApproved });
            if (res.data?.status.code === 200) {
                alert(res?.data.status?.message);
                dispatch(fetchTherapistCustomslots());
            } else if (res?.response?.data?.status.code === 400) {
                alert(res?.response?.data?.status?.message);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsSubmitting(false);
            window.location.reload();
        }
    };

    const getAdminNameById = (id) => {
        const admin = adminList.find(admin => admin.id === id);
        return admin ? admin.name : 'Unknown Admin';
    };

    const openApprovalModal = (id) => {
        setSelectedRequestId(id);
        setIsApprovalModalOpen(true);
    };

    const closeApprovalModal = () => {
        setIsApprovalModalOpen(false);
        setSelectedRequestId(null);
    };

    const handleConfirmApproval = async () => {
        if (selectedRequestId) {
            setIsSubmitting(true);
            try {
                await updateTherapistRequest(selectedRequestId, true);
                alert('Request approved successfully');
                dispatch(fetchTherapistCustomslots());
            } catch (error) {
                console.error(error);
            } finally {
                setIsSubmitting(false);
                closeApprovalModal();
            }
        }
    };

    return (
        <div>
            <Typography 
                variant="h4" 
                sx={{ 
                    textAlign: 'center', 
                    mb: 2,
                    fontSize: { xs: '1.25rem', sm: '2.125rem' }
                }}
            >
                Therapist Unavailability/Request
            </Typography>
            
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: 'center',
                    gap: 2,
                    mb: 3,
                }}
            >
                <TextField
                    placeholder="Search therapist..."
                    variant="outlined"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    sx={{
                        width: { xs: '100%', sm: 300 },
                        borderRadius: '8px',
                        height: '40px',
                        boxShadow: 'inset 0px 1px 3px rgba(0, 0, 0, 0.1)',
                        '& .MuiOutlinedInput-root': {
                            height: '40px',
                            borderRadius: '8px',
                            padding: '0 8px',
                            '& fieldset': { borderColor: '#ccc' },
                        },
                        '& .MuiOutlinedInput-input': { padding: '8px' },
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />

                {hasAdminAndSuperAdminAccess(role) && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => navigate("/addtherapist-unavailability")}
                        startIcon={<Add />}
                        sx={{
                            padding: '8px 16px',
                            backgroundColor: '#007bff',
                            color: '#fff',
                            fontWeight: 'bold',
                            borderRadius: '8px',
                            '&:hover': { backgroundColor: '#0056b3' },
                        }}
                    >
                        Add Request
                    </Button>
                )}
            </Box>

            <div className="table-container">
                <table className="schedule-table">
                    <thead>
                        <tr>
                            <th className="table-header">Name</th>
                            <th className="table-header">Phone</th>
                            <th className="table-header">Employee Id</th>
                            <th className="table-header">Start Date</th>
                            <th className="table-header">End Date</th>
                            <th className="table-header">Start Time</th>
                            <th className="table-header">End Time</th>
                            <th className="table-header">Type</th>
                            <th className="table-header">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedData?.map(schedule => (
                            <tr key={schedule.id}>
                                <td>{schedule.scheduler_therapist.name}</td>
                                <td>{schedule.scheduler_therapist.phone}</td>
                                <td>{schedule.scheduler_therapist.employeeId}</td>
                                <td>{new Date(schedule.startDate).toLocaleDateString('en-GB')}</td>
                                <td>{new Date(schedule.endDate).toLocaleDateString('en-GB')}</td>
                                <td>{schedule.startTime}</td>
                                <td>{schedule.endTime}</td>
                                <td>{schedule.type}</td>
                                <td>
                                    {schedule?.isApproved === null && hasSuperAccess() && (<button
                                        className={`${isSubmitting ? 'action-btn-disable' : 'action-btn'}`}
                                        disabled={isSubmitting}
                                        onClick={() => openApprovalModal(schedule.id)}
                                    >
                                        Approve
                                    </button>
                                    )}
                                    {/* {schedule?.isApproved === null && hasSuperAccess()&& <button className={`${isSubmitting?'action-btn-disable':'action-btn'}`} disabled={isSubmitting} onClick={() => updateTherapistRequest(schedule.id, true)}>Approve</button>} */}
                                    {schedule?.isApproved === null && hasSuperAccess() && <button className={`${isSubmitting?'action-btn-disable':'action-btn-1'}`} disabled={isSubmitting} onClick={() => updateTherapistRequest(schedule.id, false)}>Reject</button>}
                                    {schedule?.isApproved === null && !hasSuperAccess() && <StatusBadge status="pending" />}
                                    {schedule?.isApproved && <StatusBadge status="approved" adminName={getAdminNameById(schedule.adminId)} />}
                                    {schedule?.isApproved === false && <StatusBadge status="rejected" adminName={getAdminNameById(schedule.adminId)} />}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <TablePagination
                component="div"
                count={filteredData.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[100, 200, 500]}
            />
            <RequestConfirmModal
                open={isApprovalModalOpen}
                onClose={closeApprovalModal}
                onConfirm={handleConfirmApproval}
                selectedRequestId={selectedRequestId}
            />
        </div>
    );
};

export default RequestList;

const StatusBadge = ({ status, adminName }) => {
    const statusStyles = {
        pending: {
            backgroundColor: 'rgba(255, 165, 0, 0.1)',
            borderColor: '#FFA500',
            textColor: '#FFA500',
            text: 'PENDING...',
        },
        approved: {
            backgroundColor: 'rgba(200, 255, 200, 0.2)',
            borderColor: '#32CD32',
            textColor: '#32CD32',
            text: 'APPROVED',
        },
        rejected: {
            backgroundColor: 'rgba(255, 99, 71, 0.2)',
            borderColor: '#FF6347',
            textColor: '#FF6347',
            text: 'REJECTED',
        },
    };

    const { backgroundColor, borderColor, textColor, text } = statusStyles[status] || {};

    return (
        <Box
            sx={{
                display: 'inline-flex',
                alignItems: 'center',
                padding: '2px 4px',
                borderRadius: '5px',
                backgroundColor,
                border: `2px solid ${borderColor}`,
                // gap: 1,
                // boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            }}
        >
            <Typography sx={{ color: textColor, fontWeight: 'bold', fontSize: '0.9rem', letterSpacing: '0.5px' }}>
                {text}
            </Typography>
            {(status === 'approved' || status === 'rejected') && (
                <Tooltip title={`${status} by: ${adminName}`} arrow>
                    <IconButton size="small" sx={{ color: textColor }}>
                        <InfoOutlinedIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            )}
        </Box>
    );
};
