import { Editor } from '@tinymce/tinymce-react';
import React, { useEffect, useRef, useState } from 'react';
import { TextField } from '@material-ui/core';
import { Button } from '@mui/material';
import './styles.css';
import axios from 'axios';
import { getToken } from '../../components/common/userLocalStorageUtils';
import { useNavigate } from 'react-router-dom';

const AddProduct = () => {
  const productType = localStorage.getItem('productDetail');
  const productId = localStorage.getItem('productEdit');
  const editorRef = useRef(null);
  const [values, setValues] = useState({
    productName: '',
    price: 0,
    description: '',
    ingredients: '',
    shortDescription: '',
    qauntity: 0,
  });
  const [productImage, setProductImage] = useState();
  const [editImage, setEditImage] = useState(false);
  const [description, srtDescription] = useState('');
  const [ingredients, setIngredients] = useState('');
  const navigate = useNavigate()
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleImageChange = (event) => {
    const { files } = event.target;
    const file = files[0];
    console.log(file);
    const acceptedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    if (!acceptedTypes.includes(file.type)) {
      alert('Please select only image files (JPEG, JPG, PNG).');
      window.location.reload();
      return;
    }
    const reader = new FileReader();
    // const file = files[0];
    console.log('see file type ', file.type);
    reader.onloadend = () => {
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const maxDimension = 1024;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxDimension) {
            height *= maxDimension / width;
            width = maxDimension;
          }
        } else {
          if (height > maxDimension) {
            width *= maxDimension / height;
            height = maxDimension;
          }
        }
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
        const compressedDataURL = canvas.toDataURL(file.type);
        setProductImage(compressedDataURL);
      };
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const body = {
      productName: values.productName,
      description: values.description,
      ingredients: values.ingredients,
      price: Number(values.price),
      productImage: productImage,
      quantity: Number(values.qauntity),
      shortDescription: values.shortDescription,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_API_URL}/skincare-products/create`,
      body,
      {
        headers: {
          token: getToken(),
        },
      },
    );
    navigate("/skincare-products")
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    const body = {
      productName: values.productName,
      description: values.description,
      ingredients: values.ingredients,
      price: Number(values.price),
      productImage: productImage,
      quantity: Number(values.qauntity),
      shortDescription: values.shortDescription,
    };
    const response = await axios.patch(
      `${process.env.REACT_APP_BACKEND_API_URL}/skincare-products/${productId}`,
      body,
      {
        headers: {
          token: getToken(),
        },
      },
    );
    navigate("/skincare-products")
  };

  const fetchParticularData = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_API_URL}/skincare-products/${productId}`,
      {
        headers: {
          Authorization: `Basic ${process.env.REACT_APP_ADMIN_APP_KEY}`,
          token: getToken(),
        },
      },
    );

    srtDescription(res.data.data[0].description);
    setIngredients(res.data.data[0].ingredients);

    setValues({
      productName: res.data.data[0].productName,
      price: res.data.data[0].price,
      description: res.data.data[0].description,
      ingredients: res.data.data[0].ingredients,
      shortDescription: res.data.data[0].shortDescription,
      qauntity: res.data.data[0].quantity,
    });
    setProductImage(res.data.data[0].productImage);
  };

  useEffect(() => {
    if (productType === 'edit') {
      fetchParticularData();
    }
  }, [productType]);

  return (
    <div style={{ display: 'flex', gap: '40px', flexDirection: 'column' }}>
      <h3 style={{ textAlign: 'center' }}>Product Details</h3>
      <TextField
        variant="outlined"
        label="Product Name"
        name="productName"
        value={values.productName}
        onChange={handleInputChange}
        required
      />

      <div>
        <label className="add-payment-label" htmlFor="image">
          Product Image:
        </label>
        {productType !== 'edit' && (
          <input
            className="add-payment-input"
            type="file"
            id="image"
            name="image"
            // accept="image/*"
            accept=".jpeg, .jpg, .png"
            onChange={handleImageChange}
            required
          />
        )}
        {!editImage && productType === 'edit' && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: 'max-content',
              gap: '10px',
            }}
          >
            <img
              src={productImage}
              alt={values.packageName}
              width={200}
              height={200}
            />
            <button
              type="button"
              onClick={() => {
                setEditImage(true);
              }}
            >
              Remove Image
            </button>
          </div>
        )}
        {editImage && productType === 'edit' && (
          <input
            className="add-payment-input"
            type="file"
            id="image"
            name="image"
            // accept="image/*"
            accept=".jpeg, .jpg, .png"
            onChange={handleImageChange}
            required
          />
        )}
      </div>
      <TextField
        variant="outlined"
        label="Short Description"
        name="shortDescription"
        value={values.shortDescription}
        onChange={handleInputChange}
        className="no-arrow"
        required
      />

      <div>
        <h4>Description</h4>
        <Editor
          apiKey="p4oms0qiwlv8h44arpkvcva2dkamn6343jmv13aq805gj7x9"
          onInit={(_evt, editor) => (editorRef.current = editor)}
          initialValue={description}
          init={{
            height: 500,
            menubar: false,
            plugins:
              'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker permanentpen powerpaste advtable advcode editimage tableofcontents mergetags inlinecss',
            toolbar:
              'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
            content_style:
              'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          }}
          onEditorChange={(content) =>
            setValues({ ...values, description: content })
          }
        />
      </div>
      <div>
        <h4>Ingredients</h4>
        <Editor
          apiKey="p4oms0qiwlv8h44arpkvcva2dkamn6343jmv13aq805gj7x9"
          onInit={(_evt, editor) => (editorRef.current = editor)}
          initialValue={ingredients}
          init={{
            height: 500,
            menubar: false,
            plugins:
              'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker permanentpen powerpaste advtable advcode editimage tableofcontents mergetags inlinecss',
            toolbar:
              'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
            content_style:
              'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          }}
          onEditorChange={(content) =>
            setValues({ ...values, ingredients: content })
          }
        />

        <div style={{ display: 'flex', gap: '20px', marginTop: '40px' }}>
          <div>
            <TextField
              variant="outlined"
              label="Price"
              type="number"
              name="price"
              value={values.price}
              onChange={handleInputChange}
              className="no-arrow"
              required
            />
          </div>
          <div>
            <TextField
              variant="outlined"
              label="Quantity"
              name="qauntity"
              type="number"
              value={values.qauntity}
              onChange={handleInputChange}
              className="no-arrow"
              required
            />
          </div>
        </div>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={productType === 'add' ? handleSubmit : handleUpdate}
          style={{ width: '20%', marginTop: '30px' }}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default AddProduct;
