import { AppBar, Toolbar, Typography } from '@mui/material';
import sizeConfigs from "../../../../src/config/colorConfigs";
import colorConfigs from '../../../../src/config/colorConfigs';
import Tooltip from '@mui/material/Tooltip';

import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useNavigate } from 'react-router-dom';
import { logoutDoctor } from '../../../store/actions/loginAction';

const Header = ()=>{
    const navigate = useNavigate();
    const doctorData = JSON.parse(localStorage.getItem('doctorData')) || {};
    
    const logoutAndRemoveDoctor = async () => {
        try {
            const doctor_id = doctorData?.doctor?.id;

            const logoutResponse = await logoutDoctor(doctor_id);
            if (logoutResponse?.status?.code === 200) {
                localStorage.removeItem('doctorData');
                alert(logoutResponse?.status?.message);
                window.location.reload()
                navigate('/doctor/login');
            }
            else{
                localStorage.removeItem('doctorData');
                window.location.reload()
                navigate('/doctor/login');
            }
        } catch (error) {
            localStorage.removeItem('doctorData');
            window.location.reload()
            navigate('/doctor/login');
        }
    };

    return (
        <AppBar
            sx={{
            width: `calc(100% - ${sizeConfigs.sidebar.width})`,
            ml: sizeConfigs.sidebar.width,
            boxShadow: 'unset',
            backgroundColor: colorConfigs.topbar.bg,
            color: colorConfigs.topbar.color,
            }}
        >
            <Toolbar>
            <Typography className="flex-grow" align="center" variant="h5">
            </Typography>

            <Tooltip title="logout">
                <ExitToAppIcon onClick={() => logoutAndRemoveDoctor()}/>
            </Tooltip>
            </Toolbar>
        </AppBar>
    )
}

export default Header