import axios from "axios";
import { getDoctorToken, getToken } from "../../components/common/userLocalStorageUtils";

const apiUrl = process.env.REACT_APP_API_URL;

export const getZohoAppointment = async (limit, page, type, text) => {
    try {
        let search = "";
        if (type && text) {
            search = `&${type}=${text}`
        }
        const res = await
            axios.get(`${apiUrl}/api/v1/admin/zoho-appointment/appointment-list?limit=${limit}&page=${page}${search}`,
                {
                    headers: {
                        Authorization: `Basic ${process.env.REACT_APP_ADMIN_APP_KEY}`,
                        token: getToken()
                    },
                }
            );

        const { status, data } = await res.data;
        if (status.code == 200 && data?.list) {
            const filteredData = data.list.map(({ ...item }) => {
                return {
                    booking_id: item.booking_id,
                    customer_name: item.customer_name,
                    customer_contact_no: item.customer_contact_no,
                    customer_email: item.customer_email,
                    staff_name: item.staff_name,
                    staff_email: item.staff_email,
                    staff_contact_number: item.staff_contact_number,
                    service_name: item.service_name,
                    duration: item.duration,
                    cost: item.cost,
                    cost_paid: item.cost_paid,
                    start_time: item.start_time,
                    end_time: item.end_time,
                    payment_status: item.payment_status,
                    status: item.status
                };
            });
            return {
                list: filteredData,
                total: data.total || 0,
            };
        }
        return { list: [], total: 0 };
    }
    catch (error) {
        throw error;
    }
};


export const exportZohoAppointment = async (limit, page, type, text) => {
    try {
        const res = await axios.get(`${apiUrl}/api/v1/admin/zoho-appointment/export-appointment`, {
            headers: {
                Authorization: `Basic ${process.env.REACT_APP_ADMIN_APP_KEY}`,
                token: getToken()
            },
        })
        const response = await res.data;
        return response;
    } catch (error) {
        throw error;
    }
}

export const getDoctorAppointment = async (limit, page, type, text) => {
    try {
        let search = "";
        if (type && text) {
            search = `&${type}=${text}`
        }

        const res = await
            axios.get(`${apiUrl}/api/v1/doctor/appointment?limit=${limit}&page=${page}${search}`,
                {
                    headers: {
                        Authorization: `Basic ${process.env.REACT_APP_ADMIN_APP_KEY}`,
                        token: getDoctorToken()
                    },
                }
            );

        const { status, data } = await res.data;
        if (status.code == 200 && data?.list) {
            const filteredData = data.list.map(({ ...item }) => {
                return {
                    id: item.id,
                    booking_id: item.booking_id,
                    customer_name: item.customer_name,
                    service_name: item.service_name,
                    duration: item.duration,
                    start_time: item.start_time,
                    end_time: item.end_time,
                    status: item.status
                };
            });
            return {
                list: filteredData,
                total: data.total || 0,
            };
        }
        return { list: [], total: 0 };
    }
    catch (error) {
        throw error;
    }
}

export const getZohoAppointmentById = async (id) => {
    try {
        const res = await
            axios.get(`${apiUrl}/api/v1/doctor/appointment/${id}`,
                {
                    headers: {
                        Authorization: `Basic ${process.env.REACT_APP_ADMIN_APP_KEY}`,
                        token: getDoctorToken()
                    },
                }
            );

        const response = await res.data;
        return response;
    }
    catch (error) {
        throw error;
    }
}

export const getMedication = async (limit = 1000, page = 1, type = "", text = "") => {
    try {
        let search = "";
        if (type && text) {
            search = `&${type}=${text}`
        }
        const res = await
            axios.get(`${apiUrl}/api/v1/doctor/getmedication?limit=${limit}&page=${page}${search}`,
                {
                    headers: {
                        Authorization: `Basic ${process.env.REACT_APP_ADMIN_APP_KEY}`,
                        token: getDoctorToken()
                    },
                }
            );

        const { status, data } = await res.data;
        if (status.code == 200 && data?.list) {
            const fieldsForLabel = ['brand', 'medicineName', 'strength', 'composition',
                'stripCount'
            ];
            const filteredData = data.list.map(med => ({
                id: med.id,
                label: fieldsForLabel
                    .map(field => field === 'strength'
                        ? `${med[field]}mg`
                        : field === 'stripCount' ? `(${med[field]} Tab/Strip)` :
                            med[field]
                    )
                    .join(" - "),
                productId: med.productId,
                quantity: med.availableQuantity
            }));
            return {
                list: filteredData,
                total: data.total || 0,
            };
        }
        return { list: [], total: 0 };
    }
    catch (error) {
        throw error;
    }
};

export const createUserCart = async (data) => {
    try {
        const res = await axios.post(`${apiUrl}/api/v1/doctor/create-user-cart`, data, {
            headers: {
                Authorization: `Basic ${process.env.REACT_APP_ADMIN_APP_KEY}`,
                token: getDoctorToken(),
                "Content-Type": "multipart/form-data",
            },
        })
        const response = await res.data;
        return response
    } catch (error) {
        throw error;
    }
}

export const getProduct = async (gender) => {
    try {
        const res = await
            axios.get(`${apiUrl}/api/v1/doctor/product-list`,
                {
                    headers: {
                        Authorization: `Basic ${process.env.REACT_APP_ADMIN_APP_KEY}`,
                        token: getDoctorToken()
                    },
                }
            );
        const { status, productList } = await res.data;
        if (status.code == 200) {
            const filteredData = productList
                .filter(
                    person => person.categoryGender.toLowerCase() === gender.toLowerCase()
                )
                .map((product) =>
                ({
                    id: product.id,
                    productId: product.id,
                    label: product.name
                })
                )

            return {
                list: filteredData,
            };
        }
        return { list: [] };
    } catch (error) {
        throw error;
    }
}

export const getProductSession = async (id) => {
    try {
        const res = await
            axios.get(`${apiUrl}/api/v1/doctor/product-sessions/${id}`,
                {
                    headers: {
                        Authorization: `Basic ${process.env.REACT_APP_ADMIN_APP_KEY}`,
                        token: getDoctorToken()
                    },
                }
            );
        const { status, productSessions } = await res.data;
        if (status.code == 200) {
            const filteredData = productSessions.map((session) => ({
                id: session.sessionId,
                label: session.count,
                count: session.count,
                productSessionId: session.sessionId,
            }))

            return {
                list: filteredData,
            };
        }
        return { list: [] };
    } catch (error) {
        throw error;
    }
}



export const generatePaymentLink = async (bookingId) => {
    try {
        const res = await
            axios.post(`${apiUrl}/api/v1/admin/zoho-appointment/generate-payment-link/${bookingId}`, {
                bookingId: bookingId
            },
                {
                    headers: {
                        Authorization: `Basic ${process.env.REACT_APP_ADMIN_APP_KEY}`,
                        token: getToken(),
                    },
                },
            );
        const response = await res.data;
        return response;
    } catch (error) {
        throw error;
    }
}