import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, Button, CircularProgress } from '@mui/material';
import { getFailedTherapistAllocationBookingList } from '../../store/actions/therapist.action';
import { useNavigate } from 'react-router-dom';

const RequestConfirmModal = ({ open, onClose, onConfirm, selectedRequestId }) => {
    const [failedAllocations, setFailedAllocations] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (selectedRequestId && open) {
            const fetchFailedAllocations = async () => {
                setLoading(true);
                try {
                    const response = await getFailedTherapistAllocationBookingList(selectedRequestId);
                    setFailedAllocations(response);
                } catch (error) {
                    console.error("Failed to fetch allocation data:", error);
                } finally {
                    setLoading(false);
                }
            };

            fetchFailedAllocations();
        }
    }, [selectedRequestId, open]);

    const handleClose = () => {
        setFailedAllocations([]);
        setLoading(false);
        onClose();
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="approve-confirmation-modal"
            aria-describedby="modal-to-confirm-approval"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                    textAlign: 'center',
                    borderRadius: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative'
                }}
            >
                {loading && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            bgcolor: 'rgba(255, 255, 255, 0.8)',
                            zIndex: 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: 2
                        }}
                    >
                        <CircularProgress />
                    </Box>
                )}

                <Typography id="approve-confirmation-modal" variant="h6" component="h2">
                    Are you sure you want to approve this request?
                </Typography>

                {!loading && failedAllocations.length > 0 && (
                    <Box mt={2} textAlign="center">
                        <Typography color="error" mb={1} fontWeight="bold">
                            Warning:We suggest managing the bookings below first, as no therapist is available for them.
                        </Typography>
                        {failedAllocations.map((id) => (

                            <Button
                                key={id}
                                variant="outlined"
                                color="secondary"
                                onClick={() => window.open(`/booking-details/${id}`, '_blank')}
                                sx={{
                                    m: 1,
                                    color: 'black',
                                    borderColor: 'black',
                                    '&:hover': {
                                        borderColor: 'black',
                                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                    },
                                }}
                            >
                                {`Booking Id - ${id}`}
                            </Button>

                        ))}
                    </Box>
                )}
                <Box mt={2} display="flex" justifyContent="center">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onConfirm}
                        sx={{ mx: 1 }}
                        disabled={loading}
                    >
                        Yes
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={handleClose}
                        sx={{ mx: 1 }}
                        disabled={loading}
                    >
                        No
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default RequestConfirmModal;
