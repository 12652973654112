import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  TextField,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  Select,
  Container,
} from '@mui/material';
import {
  getMachineLogs,
  updateMachineDetails,
} from '../../store/actions/machine.action';

export default function EditMachine() {
  const location = useLocation();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('userData'))?.user;
  const [logs, setLogs] = useState([]);

  const data = location?.state || {
    machineId: '',
    machineName: '',
    centerId: '',
  };

  const centers = [
    { centerId: 1, centerName: 'Pune Center' },
    { centerId: 2, centerName: 'Gurgaon Center' },
    { centerId: 3, centerName: 'Chandigarh Center' },
    { centerId: 4, centerName: 'Kotla Center' },
    { centerId: 5, centerName: 'Bangalore Center' },
    { centerId: 6, centerName: 'Mumbai Center' },
    { centerId: 7, centerName: 'Noida Center' },
    { centerId: 8, centerName: 'Ludhiana Center' },
    { centerId: 9, centerName: 'Hyderabad Center' },
    { centerId: 10, centerName: 'Pitampura Center' },
    { centerId: 11, centerName: 'Ahemdabad Center' },
    { centerId: 12, centerName: 'Indore Center' },
    { centerId: 13, centerName: 'Surat Center' },
    {centerId:14,centerName:'HO Center'}
  ];

  const [machineDetails, setMachineDetails] = useState({
    ...data,
    centerId:
      data.centerId ||
      centers.find((c) => c.centerName === data.centerName)?.centerId ||
      '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMachineDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      machineId: machineDetails.machineId,
      machineName: machineDetails.machineName,
      centerId: machineDetails.centerId,
      adminId: user?.id,
      adminName: user?.name,
      adminPhone: user?.phone,
    };

    try {
      await updateMachineDetails(payload.machineId, payload);
      navigate('/machinelist');
    } catch (error) {
      console.error('Failed to update machine details:', error);
    }
  };

  useEffect(() => {
    const fetchLogs = async () => {
      if (data?.machineId) {
        try {
          const response = await getMachineLogs(data.machineId);
          setLogs(response?.data?.logs || []);
        } catch (error) {
          console.error('Failed to fetch machine logs:', error);
        }
      }
    };

    fetchLogs();
  }, [data?.machineId]);

  return (
    <Container
      maxWidth="md"
      sx={{
        padding: '40px',
        marginTop: '40px',
        boxShadow: 3,
        borderRadius: 2,
        backgroundColor: '#fff',
      }}
    >
      <Typography variant="h4" align="center" gutterBottom>
        Edit Machine Details
      </Typography>
      <br />
      <form onSubmit={handleSubmit}>
        <Box sx={{ display: 'flex', gap: '20px', marginBottom: '40px' }}>
          <TextField
            fullWidth
            label="Machine Name"
            name="machineName"
            value={machineDetails.machineName}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            label="Machine ID"
            name="machineId"
            value={machineDetails.machineId}
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>
        <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
          <FormControl fullWidth>
            <InputLabel id="center-select-label">Center</InputLabel>
            <Select
              labelId="center-select-label"
              name="centerId"
              value={machineDetails.centerId}
              label="Center"
              onChange={handleChange}
            >
              {centers.map((center) => (
                <MenuItem key={center.centerId} value={center.centerId}>
                  {center.centerName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
          >
            Submit
          </Button>
        </Box>
      </form>
      <Box sx={{ marginTop: '30px' }}>
        <Typography variant="h6" align="center" gutterBottom>
          Machine Logs
        </Typography>
        {logs.length > 0 ? (
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>ID</th>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>
                  Action
                </th>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>
                  Date
                </th>
              </tr>
            </thead>
            <tbody>
              {logs.map((log) => (
                <tr key={log.id}>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                    {log.id}
                  </td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                    {log.action}
                  </td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                    {log.createdAt.split('T')[0]}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <Typography variant="body1" align="center">
            No logs available.
          </Typography>
        )}
      </Box>
    </Container>
  );
}
