import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getToken } from "../../components/common/userLocalStorageUtils";

const apiUrl = process.env.REACT_APP_API_URL;

export const getClients = createAsyncThunk('client', async (params) => {
    try {
        const res = await
            axios.get(`${apiUrl}/api/v1/admin/client/clients?startDate=${params.startDate}&endDate=${params.endDate}&phone=${params.phone}`,
                {
                    headers: {
                        Authorization: `Basic ${process.env.REACT_APP_ADMIN_APP_KEY}`,
                        token: getToken(),
                    },
                }
            );
        const data = await res.data.clients;
        return data;
    }
    catch (error) {
        throw error;
    }
});

export const UpdateClients = async (id, data) => {
    try {
        const res = await axios.patch(`${apiUrl}/api/v1/admin/client/clients/${id}`, data, {
            headers: {
                Authorization: `Basic ${process.env.REACT_APP_ADMIN_APP_KEY}`,
                token: getToken(),
            },
        });
        const response = await res.data;
        return response;
    } catch (error) {
        throw error;
    }
}

export const DownloadClientData = async (params) => {
    try {
        const res = await
            axios.get(`${apiUrl}/api/v1/admin/client/downloadClient?startDate=${params.startDate}&endDate=${params.endDate}`,
                {
                    headers: {
                        Authorization: `Basic ${process.env.REACT_APP_ADMIN_APP_KEY}`,
                        token: getToken(),
                    },
                }
            );
        const response = await res.data;
        return response;
    } catch (error) {
        throw error;
    }
}

export const getClientById = async (id) => {
    try {
        const res = await
            axios.get(`${apiUrl}/api/v1/admin/client/getClient?id=${id}`,
                {
                    headers: {
                        Authorization: `Basic ${process.env.REACT_APP_ADMIN_APP_KEY}`,
                        token: getToken(),
                    },
                }
            );
        const response = await res.data;
        return response;
    } catch (error) {
        throw error
    }
}

export const getHealth = async (id) => {
    try {
        const res = await
            axios.get(`${apiUrl}/api/v1/admin/client/getHealth`,
                {
                    headers: {
                        Authorization: `Basic ${process.env.REACT_APP_ADMIN_APP_KEY}`,
                        token: getToken(),
                    },
                }
            );
        const response = await res.data;
        return response;
    } catch (error) {
        throw error
    }
}
