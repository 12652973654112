import {
  Autocomplete,
  Button,
  TextField,
  Typography,
  IconButton,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { getToken } from '../../components/common/userLocalStorageUtils';
import CustomComponent from './ServiceQuestionTemplate';
import { useNavigate } from 'react-router-dom';

const AddServiceQuestion = () => {
  const [categories, setCategories] = useState([]);
  const [personName, setPersonName] = useState([]);
  const [customComponents, setCustomComponents] = useState([]);
  const navigate = useNavigate();
  const fetchCategories = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_API_URL}/products/categories`,
      );
      setCategories(
        res.data
          .slice(0, 20)
          .map((item) => `${item.id}. ${item.name} - ${item.gender}`),
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleChange = (event, value) => {
    setPersonName(value);
  };

  const addCustomComponent = () => {
    setCustomComponents([...customComponents, { question: '', type: '' }]);
  };

  const handleCustomComponentChange = (index, field, value) => {
    const updatedComponents = [...customComponents];
    updatedComponents[index][field] = value;
    setCustomComponents(updatedComponents);
  };

  const handleSubmit = () => {
    const categoryIds = personName.map((item) => Number(item.split('.')[0]));
    const body = {
      categoryId: categoryIds,
      questions: customComponents,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/v1/admin/client/add-category-questions`,
        body,
        {
          headers: {
            Authorization: `Basic ${process.env.REACT_APP_ADMIN_APP_KEY}`,
            token: getToken(),
          },
        },
      )
      .then((response) => {
        navigate('/service-questions');
        console.log('Submitted successfully:', response.data);
      })
      .catch((error) => {
        console.error('Error submitting data:', error);
      });
  };

  return (
    <div>
      <Typography variant="h4" align="center" gutterBottom>
        Add Service Questions
      </Typography>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={categories}
        value={personName}
        onChange={handleChange}
        multiple
        renderInput={(params) => (
          <TextField variant="outlined" {...params} label="Categories" />
        )}
        sx={{ marginBottom: '20px' }}
      />
      {customComponents.map((component, index) => (
        <CustomComponent
          key={index}
          index={index}
          data={component}
          onChange={handleCustomComponentChange}
        />
      ))}
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <IconButton
          color="primary"
          onClick={addCustomComponent}
          sx={{ marginTop: '10px' }}
        >
          <AddCircleOutlineIcon />
        </IconButton>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          style={{ width: '20%', marginTop: '30px' }}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default AddServiceQuestion;
