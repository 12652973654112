
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAdmin, fetchCity } from '../../store/actions/center.action';
import { Grid, Button, Paper, Typography, Box, IconButton } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import FormFields from './FormComponents';
import { hasAdminAccess } from '../../components/common/UserRolesConfig';
import { UpdateClients, getHealth } from '../../store/actions/client.action';

import {OCCUPATION_OPTIONS, SOURCE_OPTIONS, GENDER_OPTIONS, EDUCATION_OPTIONS} from "../../constants/index"
import { hasClientEditAccess } from '../../utils/access';

const ClientDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const role = JSON.parse(localStorage.getItem('userData'))?.user?.role;
    const cityList = useSelector((state) => state.center?.cityList?.cities);
    const location = useLocation();
    const data = location?.state?.clientDetails;
    const clientId = location?.state?.clientDetails?.id
    const [formData, setFormData] = useState({
        clientName: '',
        primaryContact: '',
        alternateContact: '',
        age: "",
        gender: "",
        location: "",
        education: "",
        educationtxt: "",
        occupation: "",
        height: "",
        weight: "",
        source: "",
        faceWash: "",
        moisturizer: "",
        sunscreen: "",
        serum: "",
        health: [],
        healthtxt: "" 
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [healthList, setHealthList] = useState([])
    useEffect(() => {
        dispatch(fetchCity());
        dispatch(fetchAdmin());
    }, [dispatch]);

    const getHealthData = async () =>{
        try {
          const health = await getHealth();
          
          if(health?.status && health?.healthOption?.length){
            setHealthList(health.healthOption)
          }
          
        } catch (error) {}
      }
  
    useEffect(()=>{
        getHealthData()
    },[]) 

    useEffect(() => {
        if (data) {
            const newData = {
                clientName: data?.["clientName"],
                primaryContact: data?.["primaryContact"],
                alternateContact: data?.["alternateContact"],
                age: data?.["age"],
                gender: data?.["gender"],
                location: data?.["location"],
                education: data?.["education"],
                occupation: data?.["occupation"],
                height: data?.["height"],
                weight: data?.["weight"],
                source: data?.["source"],
                faceWash: data?.["faceWash"],
                moisturizer: data?.["moisturizer"],
                sunscreen: data?.["sunscreen"],
                serum: data?.["serum"],
                onboarded: data?.["is_onboarded"],
                city: data?.["city"],
                educationtxt: data?.["educationtxt"],
                health: data?.health ?? [],
                healthtxt: data?.healthtxt
            };
            setFormData(newData);
        }
    }, [data]);

    const handleChange = (e) => {        
        const { name, value } = e.target;
        
        let updatedFormData = { ...formData };
        updatedFormData = {
            ...updatedFormData,
            [name]: name == 'heath'? typeof value === 'string' ? value.split(',') : value : value,
        };

        setFormData(updatedFormData);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            const res = await UpdateClients(clientId, formData);
            if (res?.status?.code === 200) {
                alert(res?.status?.message);
                navigate('/clients');
            } else if (res?.status?.code === 400) {
                alert(res?.status?.message);
            } else {
                alert('Unhandled response:', res);
            }
        } catch (error) {   
            alert('An error occurred. Please try again later.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Paper elevation={3} 
            sx={{ 
                padding: 4, margin: 'auto', maxWidth: 800, marginTop: 2, borderRadius: 2 
            }}>
            <Box sx={{position: 'relative'}}>
                <IconButton 
                    color='primary' 
                    onClick={() => navigate('/clients')} 
                    sx={{position: 'absolute', top: 0, left: 0}}>
                    <KeyboardBackspaceIcon />
                </IconButton>
                <Typography variant="h4" 
                    align="center" 
                    gutterBottom sx={{ color: '#0077b6', fontWeight: 'bold' }}
                >
                    {formData.clientName.length > 0 ? formData.clientName : 'Client Name'}
                </Typography>
            </Box>
            <Box component="form" marginTop={5} onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <FormFields 
                        formData={formData} 
                        handleChange={handleChange}
                        occupationList={OCCUPATION_OPTIONS}
                        sourceList={SOURCE_OPTIONS}
                        genderList={GENDER_OPTIONS}
                        educationList={EDUCATION_OPTIONS}
                        healthList={healthList}
                    />
                    { (hasAdminAccess(role) || hasClientEditAccess()) && (
                        <Grid item xs={12}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={isSubmitting}
                                sx={{ padding: '12px', fontWeight: 'bold', fontSize: '16px' }}
                            >
                                {
                                    isSubmitting ? 'Updating' : 'Update'
                                }
                            </Button>
                        </Grid>
                    )}

                </Grid>
            </Box>
        </Paper>
    );
};

export default ClientDetails;