import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const Dropdown = ({
  label,
  options = [],
  value,
  onChange,
  fullWidth = true,
  variant = "outlined"
}) => {

  const changeHandler = (event) => {
    onChange(event.target.value)
  }

  return (
    <FormControl style={{ width: 500 }} fullWidth={fullWidth} variant={variant}>
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        onChange={changeHandler}
        label={label}
        displayEmpty
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Dropdown;
