import React from 'react';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { InputLabel } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setSearchText, setSearchType, setSelectedCities } from '../../../store/slices/dashboardStateSlice';
import './SearchComponent.style.css';

const SearchComponent = ({
  searchText,
  searchType,
  searchBtnPressed,
  setSearchBtnPressed,
  cityList = [
    'Delhi',
    'Noida',
    'Gurgaon',
    'Pune',
    'Mumbai',
    'Hyderabad',
    'Bangalore',
    'Indore',
    'Ahmedabad',
    'Ludhiana',
    'Chandigarh',
    'Surat'
  ],
  showCityFilter = false,
}) => {
  const dispatch = useDispatch();
  const [selectedCity, setSelectedCity] = React.useState('');

  // Handle search type change
  const handleTypeChange = (event) => {
    dispatch(setSearchType(event.target.value));
  };

  // Handle city selection
  const handleCityChange = (event) => {
    setSelectedCity(event.target.value)
    dispatch(setSelectedCities(event.target.value))
  };

  // Handle search button click
  const handleSearch = () => {
    if ((searchText.trim()).length < 1) {
      return;
    }
    setSearchBtnPressed(!searchBtnPressed);
  };

  // Handle Enter key press for search
  const handleSearchOnKeyDown = (e) => {
    if ((searchText.trim()).length < 1) {
      return;
    }
    if (e.key === 'Enter') {
      e.preventDefault();
      setSearchBtnPressed(!searchBtnPressed);
    }
  };

  // Handle text change in search input
  const handleTextChange = (e) => {
    dispatch(setSearchText(e.target.value));
  };

  return (
    <div className="search-component">
      <FormControl sx={{ minWidth: 120 }} size="small">
        <InputLabel id="search-type-label">Filter Type</InputLabel>
        <Select
          labelId="search-type-label"
          id="search-type-select"
          value={searchType}
          label="Filter Type"
          onChange={handleTypeChange}
        >
          <MenuItem value="phoneNumber">Client Phone</MenuItem>
          <MenuItem value="partnerName">Partner Name</MenuItem>
          <MenuItem value="name">Client Name</MenuItem>
        </Select>
      </FormControl>

      {showCityFilter && (
        <FormControl sx={{ minWidth: 120, marginLeft: 2 }} size="small">
          <InputLabel id="city-filter-label">City</InputLabel>
          <Select
            labelId="city-filter-label"
            id="city-filter-select"
            value={selectedCity}
            label="City"
            onChange={handleCityChange}
          >
            {cityList.map((city) => (
              <MenuItem key={city} value={city}>
                {city}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <TextField
        placeholder="Search"
        variant="outlined"
        size="small"
        value={searchText}
        type={searchType === "phoneNumber" ? "number" : "text"}
        required
        onChange={handleTextChange}
        onKeyDown={handleSearchOnKeyDown}
        InputProps={{
          endAdornment: (
            <IconButton color="primary" onClick={handleSearch}>
              <SearchIcon />
            </IconButton>
          ),
        }}
        sx={{ marginLeft: showCityFilter ? 2 : 0 }}
      />
    </div>
  );
};

export default SearchComponent;
