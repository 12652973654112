import { createSlice } from "@reduxjs/toolkit";
import { getClients } from "../actions/client.action";

const initialState = {
    clientsList: [],
    isLoading: false,
    error: null,
};

const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {},

    extraReducers: (builder) => {
        builder.addCase(getClients.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getClients.fulfilled, (state, action) => {
            state.isLoading = false;
            state.clientsList = action.payload;
        });
        builder.addCase(getClients.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message;
        });
    },
});

export default clientSlice.reducer;
