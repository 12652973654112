
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  TextField,
  MenuItem,
  Box,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { clientLocation } from '../../slotData/slotData';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  textAlign: 'center',
  fontWeight: 'bold',
  fontSize: '0.875rem',
  padding: '8px',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const SlotTable = () => {
  const [dates, setDates] = useState([]);
  const [products, setProducts] = useState([]);
  const [slotData, setSlotData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [previousSlotData, setPreviousSlotData] = useState(null);
  const [selectedCenter, setSelectedCenter] = useState('Kotla');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);

  const centers = [
    'Kotla',
    'Pitampura',
    'Chandigarh',
    'Ludhiana',
    'Hyderabad',
    'Indore',
    'Noida',
    'Bangalore',
    'Gurgaon',
    'Ahmedabad',
    'Mumbai',
    'Pune',
    'Surat',
  ];

  const generateNext15Days = () => {
    const dateArray = [];
    const today = new Date();
    for (let i = 0; i <= 15; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() + i);
      dateArray.push(date.toISOString().split('T')[0]);
    }
    setDates(dateArray);
  };

  useEffect(() => {
    generateNext15Days();
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SCHEDULER_API_URL}/api/v1/misc/get-products`,
      );

      const prods = response.data.productList;

      const excluding_products = [
        'Hydrating Moisturizer',
        'Insta Glow Serum',
        'Brightening Sunscreen SPF50',
        'After Laser Hair Reduction Skincare',
        'Deep Cleansing Anti-Acne Face Wash',
        'GlowGuard Essentials Kit',
      ];

      const filteredProds = prods.filter(
        (item) => !excluding_products.includes(item.name),
      );

      console.log(filteredProds);

      setProducts(filteredProds);
    } catch (error) {
      console.error('Error fetching products:', error);
      setError(true);
    }
  };

  const fetchSlotData = async () => {
    setLoading(true);
    const productIds = products.map((product) => product.id);
    const centerInfo = clientLocation[selectedCenter.toLowerCase()];

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SCHEDULER_API_URL}/api/v1/slot/get-client-slot-for-ops`,
        {
          city: centerInfo.city,
          clientLat: centerInfo.lat,
          clientLong: centerInfo.long,
          isDashboard: '',
          slotDates: dates,
          products: productIds,
        },
      );
      setSlotData(response.data.slots);
      setPreviousSlotData(response.data.slots);
      setError(false);
    } catch (error) {
      console.error('Error fetching slot data:', error);
      setError(true);
      if (previousSlotData) setSlotData(previousSlotData);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (dates.length > 0 && products.length > 0) {
      fetchSlotData();
    }
  }, [dates, products, selectedCenter]);

  const groupSlotsIntoOneHour = (slots) => {
    let oneHourSlots = [];
    for (let i = 0; i < slots.length; i++) {
      const startHour = slots[i]?.clientSlotStartTime?.split(':')[0];
      const endHour = `${parseInt(startHour, 10) + 1}:00`;
      const slotExists = oneHourSlots.some(
        (slot) => slot.clientSlotStartTime.split(':')[0] === startHour,
      );

      if (!slotExists) {
        oneHourSlots.push({
          clientSlotStartTime: slots[i]?.clientSlotStartTime,
          clientSlotEndTime: endHour,
        });
      }
    }
    return oneHourSlots;
  };

  const colorMessages = {
    '#4169E1': 'Machine Not Mapped',
    '#FFD700': 'Therapist Not Mapped',
    '#FFB6C1': 'Therapist Not Available',
    '#CEA2FD': 'Machine Not Available',
    'linear-gradient(135deg, #FFA07A, #CEA2FD)':
      'No Therapist/Machine Intersection',
    lightgreen: 'Slots Available',
    '#DE305C': 'No Slots Available',
  };

  const getSlotInfo = (date, productId) => {
    const slotEntry = slotData[date]?.[productId];
    if (!Array.isArray(slotEntry)) {
      return slotEntry || 'No Data';
    }
    const groupedSlots = groupSlotsIntoOneHour(slotEntry);
    return groupedSlots.length;
  };

  const getCellStyle = (slotInfo) => {
    switch (slotInfo) {
      case 'Failed to fetch center Machine List With Serviceable Products And Slots!':
        return {
          backgroundColor: '#4169E1',
          color: '#000',
          fontWeight: 'bold',
        };
      case 'Failed to fetch center Therapist List With Serviceable Products And Slots!':
        return {
          backgroundColor: '#FFD700',
          color: '#fff',
          fontWeight: 'bold',
        };
      case 'Failed to fetch Candidate Therapist list with slots!':
        return {
          backgroundColor: '#FFB6C1',
          color: '#000',
          fontWeight: 'bold',
        };
      case 'Failed to fetch Candidate Machine List With Slots list with slots!':
        return {
          backgroundColor: '#CEA2FD',
          color: '#000',
          fontWeight: 'bold',
        };
      case 'Failed to find intersection of slots!':
        return {
          background: 'linear-gradient(135deg, #FFA07A, #CEA2FD)',
          color: '#000',
          fontWeight: 'bold',
        };
      default:
        return slotInfo > 0
          ? { backgroundColor: 'lightgreen', fontWeight: 'bold' }
          : { backgroundColor: '#DE305C', color: '#000', fontWeight: 'bold' };
    }
  };

  const handleDownloadCSV = async () => {
    const filteredSlots = applyFilters(slotData, startDate, endDate);

    const filteredDates = Object.keys(filteredSlots).map((item) =>
      item.toString(),
    );

    const getSlotMessage = (slotInfo) => {
      const color =
        getCellStyle(slotInfo).backgroundColor ||
        getCellStyle(slotInfo).background;
      return color === 'lightgreen'
        ? slotInfo
        : colorMessages[color] || 'No Slots Available';
    };

    const csvRows = [
      ['Product', ...filteredDates],
      ...products.map((product) => [
        `${product.name} - ${product.gender}`,
        ...filteredDates.map((date) => {
          const slotInfo = getSlotInfo(date, product.id);
          const message = getSlotMessage(slotInfo);
          return message;
        }),
      ]),
    ];

    const csvContent = csvRows.map((row) => row.join(',')).join('\n');
    console.log(csvContent);
    const blob = new Blob([csvContent], { type: 'text/csv' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'result.csv';
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const applyFilters = (data) => {
    const start = new Date(startDate).getTime();
    const end = new Date(endDate).getTime();

    const filteredData = Object.keys(data).reduce((acc, date) => {
      const currentDate = new Date(date).getTime();

      if (currentDate >= start && currentDate <= end) {
        acc[date] = data[date];
      }

      return acc;
    }, {});
    return filteredData;
  };

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
        sx={{ margin: 2 }}
      >
        Export
      </Button>

      <Dialog open={dialogOpen} onClose={handleClose}>
        <DialogTitle>Filter Data for Export</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                type="date"
                label="From Date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                fullWidth
                sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                InputProps={{
                  inputProps: {
                    min: startDate || new Date().toISOString().split('T')[0], // Minimum is the selected start date or today
                    max: new Date(new Date().setDate(new Date().getDate() + 15))
                      .toISOString()
                      .split('T')[0], // Maximum is 15 days from today
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="date"
                label="To Date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                fullWidth
                sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                InputProps={{
                  inputProps: {
                    min: startDate || new Date().toISOString().split('T')[0], // Minimum is the selected start date or today
                    max: new Date(new Date().setDate(new Date().getDate() + 15))
                      .toISOString()
                      .split('T')[0], // Maximum is 15 days from today
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                select
                label="Choose Center"
                value={selectedCenter}
                onChange={(e) => setSelectedCenter(e.target.value)}
                fullWidth
                sx={{ backgroundColor: '#fff', borderRadius: 1 }}
              >
                {centers.map((center) => (
                  <MenuItem key={center} value={center}>
                    {center}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              handleDownloadCSV();
              handleClose();
            }}
            color="primary"
          >
            Download CSV
          </Button>
        </DialogActions>
      </Dialog>

      <TableContainer
        component={Paper}
        sx={{
          padding: '10px',
          boxShadow: 3,
          height: 'calc(100vh - 120px)',
          position: 'relative',
        }}
      >
        <Typography variant="h4" align="center" gutterBottom>
          Slots Availability
        </Typography>
        <Grid
          container
          spacing={2}
          alignItems="center"
          direction="column"
          sx={{ mb: 2 }}
        >
          <Grid item xs={12} md={8}>
            <Box
              sx={{
                p: 2,
                borderRadius: 2,
                border: '1px solid #ddd',
                backgroundColor: '#F9F9F9',
                width: '100%',
              }}
            >
              <Grid
                container
                spacing={1}
                alignItems="center"
                justifyContent="center"
                sx={{ flexWrap: { xs: 'wrap', sm: 'nowrap' } }}
              >
                <Grid item xs={12} sm={6} md="auto">
                  <Box
                    sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box
                        sx={{
                          width: 16,
                          height: 16,
                          backgroundColor: '#4169E1',
                          borderRadius: '50%',
                        }}
                      />
                      <Typography variant="caption">
                        Machine Not Mapped
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box
                        sx={{
                          width: 16,
                          height: 16,
                          backgroundColor: '#FFD700',
                          borderRadius: '50%',
                        }}
                      />
                      <Typography variant="caption">
                        Therapist Not Mapped
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box
                        sx={{
                          width: 16,
                          height: 16,
                          backgroundColor: '#FFB6C1',
                          borderRadius: '50%',
                        }}
                      />
                      <Typography variant="caption">
                        Therapist Not Available
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md="auto">
                  <Box
                    sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box
                        sx={{
                          width: 16,
                          height: 16,
                          backgroundColor: '#CEA2FD',
                          borderRadius: '50%',
                        }}
                      />
                      <Typography variant="caption">
                        Machine Not Available
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box
                        sx={{
                          width: 16,
                          height: 16,
                          background: '#DE305C',
                          borderRadius: '50%',
                        }}
                      />
                      <Typography variant="caption">
                        No Machine & Therapist
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box
                        sx={{
                          width: 16,
                          height: 16,
                          background:
                            'linear-gradient(135deg, #FFA07A, #CEA2FD)',
                          borderRadius: '50%',
                        }}
                      />
                      <Typography variant="caption">
                        No Therapist/Machine Intersection
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={8} sx={{ width: '70%' }}>
            <TextField
              select
              label="Choose Center"
              value={selectedCenter}
              onChange={(e) => setSelectedCenter(e.target.value)}
              fullWidth
              sx={{ backgroundColor: '#fff', borderRadius: 1 }}
            >
              {centers.map((center) => (
                <MenuItem key={center} value={center}>
                  {center}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Table stickyHeader sx={{ position: 'relative' }}>
          {loading && (
            <Box
              sx={{
                position: 'absolute',
                height: 600,
                left: 0,
                right: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                zIndex: 2,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                padding: 4,
                borderRadius: '4px',
              }}
            >
              <CircularProgress color="inherit" />
              <Typography variant="h6" sx={{ mt: 2 }}>
                Loading slots...
              </Typography>
            </Box>
          )}
          {error && (
            <Box
              sx={{
                position: 'absolute',
                height: 600,
                left: 0,
                right: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                zIndex: 2,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                padding: 4,
                borderRadius: '4px',
              }}
            >
              <ErrorOutlineIcon color="error" sx={{ fontSize: 48, mb: 1 }} />
              <Typography variant="h6" color="error">
                Error loading slots data.
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Displaying cached data if available.
              </Typography>
            </Box>
          )}
          <TableHead>
            <TableRow>
              <StyledTableCell
                sx={{
                  position: 'sticky',
                  left: 0,
                  zIndex: 2,
                  backgroundColor: '#F5F5F5',
                  fontWeight: 'bold',
                }}
              >
                Product
              </StyledTableCell>
              {dates.map((date) => (
                <StyledTableCell
                  key={date}
                  align="center"
                  sx={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                    backgroundColor: '#F5F5F5',
                    fontWeight: 'bold',
                  }}
                >
                  {date}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product) => (
              <StyledTableRow key={product.id}>
                <StyledTableCell
                  component="th"
                  scope="row"
                  sx={{
                    fontWeight: 'bold',
                    position: 'sticky',
                    left: 0,
                    zIndex: 1,
                    backgroundColor: '#F5F5F5',
                  }}
                >
                  {product.name} - {product.gender}
                </StyledTableCell>
                {dates.map((date) => {
                  let slotInfo = getSlotInfo(date, product.id);
                  const cellStyle = getCellStyle(slotInfo);
                  const displayValue = [
                    'Failed to fetch center Machine List With Serviceable Products And Slots!',
                    'Failed to fetch center Therapist List With Serviceable Products And Slots!',
                    'Failed to fetch Candidate Therapist list with slots!',
                    'Failed to fetch available Machine List For Given Slot!',
                    'Failed to fetch available Machine List For Given Slot !',
                    'Failed to fetch Candidate Machine List With Slots list with slots!',
                    'Failed to find intersection of slots!',
                  ].includes(slotInfo)
                    ? 0
                    : slotInfo;
                  return (
                    <StyledTableCell key={date} align="center" sx={cellStyle}>
                      {displayValue}
                    </StyledTableCell>
                  );
                })}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default SlotTable;
