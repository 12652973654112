import { useEffect, useState } from "react";
import LoaderComponent from "../../../components/common/LoaderComponent/LoaderComponent";
import {getMedication, deleteMedicationById, exportMedication} from "../../../store/actions/medication.action"
import TableComponent from "../../../components/common/TableComponent/TableComponent";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import { Button, FormControl, InputLabel, Select, MenuItem, TextField, 
    IconButton, Pagination, Stack
} from "@mui/material";
import { Search } from "@mui/icons-material";
import DownloadIcon from '@mui/icons-material/Download';

const Medication = () =>{
    const navigate = useNavigate()
    const limit = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [medication, setMedication] = useState({
        medicationList: [],
        totalMedication: 0,
        isLoading: false,
        totalPages: 1
    })

    const [search, setSearch] = useState({
        type: "",
        text: "",
        searchClick: false
    })

    const handlePageChange = (event, value) => {
        if (value >= 1 && value <= medication.totalPages) {
          setCurrentPage(value);
        }
    };
    
    const getMedicationDetails = async()=>{
        setMedication((prevState)=>({
            ...prevState,
            isLoading: true
        }))
        try {
            const response = await getMedication(limit, currentPage, search.type, search.text)
            setMedication((prevState) => ({
                ...prevState,
                medicationList: response.list,
                totalMedication: response.total,
                totalPages: Math.ceil(response.total/limit),
                isLoading: false,
            }));
        } catch (error) {
            setMedication((prevState)=>({
                ...prevState,
                isLoading: false
            }))
        }
    }

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            getMedicationDetails();
        }, 500);
      
        return () => {
            clearTimeout(debounceTimer);
        };

    }, [currentPage, search.searchClick, search.text])

    const handleViewDetails=(data)=>{
        if(data){
            navigate(`/medications/view-medication/${data?.id}`, {state: data})
        }
    }

    const handleDelete = async (id) => {
        try {
            if(id)
            {
                const response = await deleteMedicationById(id)
                if(response?.status?.code == 200){
                    getMedicationDetails()
                }
            }
            else{
                window.alert("please provide medication id")
            }

            toast.dismiss();
        } catch (error) {
          console.error('Error deleting machine:', error);
        }
    };

    const handleUpdate = (data) => {
        if (data) {
            toast(
            <div className="custom-toast-btn">
                <b>Brand: {data.brand}</b>
                <b>Medicine Name: {data.medicineName}</b>
                <b>Are you sure you want to delete this medication?</b>
                <div className="toast-delete-btn">
                    <button className="yes-btn-confirm" onClick={() =>handleDelete(data?.id)}>Yes</button>
                    <button className="no-btn-confirm" onClick={() => toast.dismiss()}>No</button>
                </div>
            </div>,
            {
              autoClose: false,
              closeOnClick: false,
              position: "top-center",
            }
          );
        }
        return true;
    };
    
    const fetchClientData = async (e) => {
        e.preventDefault();
        try {
            const data = await exportMedication();
            
            if (data?.status.code === 200 && data?.downloadLinks) {
                const link = data.downloadLinks.csv;
                if (link) {
                    window.location.href = link;
                } else {
                    alert('No valid download link available');
                }
            } else {
                alert('Error occurred while generating download link');
            }
        } catch (error) {
            alert('Error occurred while generating download link');
        }
    };

    return(
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: "30px" }}>
                <h3 style={{ margin: '0 auto' }}>Medication</h3>
                <Button variant="contained" color="primary" onClick={() => navigate("/medications/add-medication")}>Add Medication</Button>
            </div>
            <SearchComponent                     
                search={search}
                setSearch={setSearch}
            />
            <Stack direction="row" spacing={2} mb={3} style={{alignItems: 'center', justifyContent: 'space-between'}}>
                <div>
                    Total Medication: {medication.totalMedication}
                </div>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<DownloadIcon />}
                    onClick={(e) => fetchClientData(e)}
                >
                    Export
                </Button>
            </Stack>
            {
                medication?.isLoading ? 
                (
                    <LoaderComponent/>
                ):
                (
                    <>
                        <TableComponent
                            data={medication.medicationList}
                            viewButton={'view'}
                            viewDetails={handleViewDetails}
                            showUpdateButton={"Delete"}
                            updateDetails={handleUpdate}
                            hiddenFields={["id"]}
                        />
                    </>
                )
            }
            <div className="incentiv-pagination" style={{ marginTop: '1rem' }}>
                <Stack spacing={3}>
                    <Pagination
                        count={medication.totalPages} page={currentPage} onChange={handlePageChange}
                        color="primary"
                    />
                </Stack>
            </div>
            <ToastContainer/>
        </>
    )
}


export default Medication


const SearchComponent = ({
    search,
    setSearch,
  }) => {  

    const handleSearch = () => {
        if ((search.text.trim()).length < 1) {
            return;
        }
        setSearch((prevState) =>({
            ...prevState,
            "searchClick": !prevState.searchClick
        }))
    };

    const handleChange = (event) => {
        const type = event.target.value;
        setSearch((prevState) =>({
            ...prevState,
            "text": "",
            "type": type
        }))
    };
  
    const handleSearchOnClick = (e) => {
        if ((search.text.trim()).length < 1) {
            return;
        }
        if (e.key === 'Enter') {
            e.preventDefault();  
            setSearch((prevState) =>({
                ...prevState,
                "searchClick": !prevState.searchClick
            }))
        }
    };

    const handleTextChange = (e) => {
        const text = e.target.value
        setSearch((prevState) =>({
            ...prevState,
            "text": text
        }))
    }

    return (
      <>
        <FormControl sx={{ minWidth: 200, marginBottom: 2 }} size="small">
          <InputLabel id="demo-simple-select-standard-label">
            Filter Type
          </InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={search.type}
            label="searchType"
            onChange={handleChange}
          >
            <MenuItem value="brand">Brand</MenuItem>
            <MenuItem value="medicineName">Medicine Name</MenuItem>
            <MenuItem value="productCategory">Product Category</MenuItem>
          </Select>
        </FormControl>
  
        <TextField
          placeholder="Search"
          variant="outlined"
          size="small"
          value={search.text}
          type={"text"}
          required={true}
          onChange={handleTextChange}
          onKeyDown={handleSearchOnClick}
          InputProps={{
            endAdornment: (
              <IconButton color="primary" onClick={handleSearch}>
                <Search />
              </IconButton>
            ),
          }}
          sx={{marginBottom: 2}}
        />
      </>
    );
};