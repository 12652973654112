import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

import { Button, Stack, TextField } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import GetAppIcon from '@mui/icons-material/GetApp';
import SearchIcon from '@mui/icons-material/Search';
import LoaderComponent from '../../components/common/LoaderComponent/LoaderComponent';
import TableComponent from '../../components/common/TableComponent/TableComponent';

import { getClients, DownloadClientData } from '../../store/actions/client.action';
import { fetchCity } from '../../store/actions/center.action';
import { OCCUPATION_OPTIONS, SOURCE_OPTIONS, GENDER_OPTIONS, EDUCATION_OPTIONS } from "../../constants/index";

const ClientList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    const storedStartDate = sessionStorage.getItem('clientStartDate') || today.toISOString().split('T')[0];
    const storedEndDate = sessionStorage.getItem('clientEndDate') || tomorrow.toISOString().split('T')[0];
    const storedPhone = sessionStorage.getItem('clientPhone') || '';

    const clientList = useSelector((state) => state.clients.clientsList);
    const cityList = useSelector((state) => state.center?.cityList?.cities);

    const [clientData, setClientData] = useState([]);
    const [startDate, setStartDate] = useState(storedStartDate);
    const [endDate, setEndDate] = useState(storedEndDate);
    const [phone, setPhone] = useState(storedPhone);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        dispatch(fetchCity());
    }, [dispatch]);

    const fetchData = () => {
        setIsLoading(true);
        try {
            sessionStorage.setItem('clientStartDate', startDate);
            sessionStorage.setItem('clientEndDate', endDate);
            sessionStorage.setItem('clientPhone', phone);

            const obj = {
                startDate: startDate,
                endDate: endDate,
                phone: phone.trim(),
            };
            dispatch(getClients(obj));
        } catch (error) {
            console.error('Error fetching client data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [dispatch, startDate, endDate]);

    useEffect(() => {
        const list = clientList.map((data) => {
            const source = SOURCE_OPTIONS.find(option => option.id === data.source);
            const occupation = OCCUPATION_OPTIONS.find(option => option.id === data.occupation);
            const gender = GENDER_OPTIONS.find(option => option.id === data.gender);
            const onboarded = data.is_onboarded === 1 ? "Yes" : "No";
            const education = EDUCATION_OPTIONS.find(option => option.id === data?.education);
            return {
                ...data,
                Education: education ? (education.label === "Other" ? data.educationtxt : education.label) : "",
                city: data.city,
                onboarded: onboarded,
                Source: source ? source.label : "",
                Occupation: occupation ? occupation.label : "",
                Gender: gender ? gender.label : "",
            };
        });
        setClientData(list);
    }, [clientList, cityList]);

    useEffect(() => {
        window.addEventListener('beforeunload', clearSpecificSessionData);
        return () => {
            window.removeEventListener('beforeunload', clearSpecificSessionData);
        };
    }, []);

    const clearSpecificSessionData = () => {
        sessionStorage.removeItem('clientStartDate');
        sessionStorage.removeItem('clientEndDate');
        sessionStorage.removeItem('clientPhone');
    };

    const handleDateChange = (event) => {
        if (event.target.name === 'startDate') {
            setStartDate(event.target.value);
        } else if (event.target.name === 'endDate') {
            setEndDate(event.target.value);
        }
    };

    const handlePhoneChange = (event) => {
        setPhone(event.target.value);
    };

    const handleSearchClick = () => {
        fetchData();
    };

    const handleViewDetails = (clientDetails) => {
        navigate('/clientdetails', { state: { clientDetails } });
    };

    const fetchClientData = async (e, format) => {
        e.preventDefault();
        try {
            const data = await DownloadClientData({ startDate, endDate, phone });
            if (data?.status.code === 200 && data?.downloadLinks) {
                const link = format === 'csv' ? data.downloadLinks.csv : data.downloadLinks.excel;
                if (link) {
                    window.location.href = link;
                } else {
                    alert('No valid download link available');
                }
            } else {
                alert('Error occurred while generating download link');
            }
        } catch (error) {
            alert('Error occurred while generating download link');
        }
    };

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: "30px" }}>
                <h3 style={{ margin: '0 auto' }}>Clients</h3>
                <Stack direction="row" spacing={2} mb={3} justifyContent="flex-end">
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<DownloadIcon />}
                        onClick={(e) => fetchClientData(e, 'csv')}
                    >
                        CSV
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<GetAppIcon />}
                        onClick={(e) => fetchClientData(e, 'excel')}
                    >
                        Excel
                    </Button>
                </Stack>
            </div>

            <div className="filters" style={{ display: 'flex', gap: '10px', margin: '30px', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <label>Start Date</label>
                    <input
                        type="date"
                        name="startDate"
                        value={startDate}
                        onChange={handleDateChange}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <label>End Date</label>
                    <input
                        type="date"
                        name="endDate"
                        value={endDate}
                        onChange={handleDateChange}
                        min={new Date(new Date(startDate).getTime() + 24 * 60 * 60 * 1000).toISOString().split('T')[0]}
                    />
                </div>
                <TextField
                    label="Search by Phone"
                    variant="outlined"
                    value={phone}
                    onChange={handlePhoneChange}
                    style={{ flex: '1' }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<SearchIcon />}
                    onClick={handleSearchClick}
                >
                    Search
                </Button>
            </div>

            {
                isLoading
                    ? (<LoaderComponent />)
                    : (
                        <TableComponent
                            data={clientData}
                            hiddenFields={['health', 'healthtxt', 'education', 'educationtxt', 'user_id', 'id', 'source', 'occupation', 'gender', 'location', 'is_onboarded']}
                            viewButton={'View'}
                            viewDetails={handleViewDetails}
                        />
                    )
            }
        </>
    );
};

export default ClientList;