import { useEffect, useState } from 'react'
import { Paper, TextField, Accordion, Grid, Box, AccordionSummary, AccordionDetails } from '@mui/material';
import { getClientById } from '../../store/actions/client.action';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {OCCUPATION_OPTIONS, SOURCE_OPTIONS, GENDER_OPTIONS, EDUCATION_OPTIONS} from "../../constants/index"

const Client = ({data})=>{
    const [client, setClient] = useState([])

    const clientData = async () =>{
      try {
        const response = await getClientById(data['User ID'])
        if(response?.status?.code === 200 && response?.clients?.length > 0){
          let clientList = response.clients[0];
          const source = SOURCE_OPTIONS.find(option => option.id == clientList.source);
          const occupation = OCCUPATION_OPTIONS.find(option => option.id == clientList.occupation)
          const gender = GENDER_OPTIONS.find(option => option.id == clientList.gender)
          const education = EDUCATION_OPTIONS.find(option => option.id == clientList?.education)           
          clientList.gender = gender? gender.label: "",
          clientList.occupation = occupation ? occupation.label: "",
          clientList.source = source ? source.label : "",
          clientList.location = clientList.city,
          clientList.education = education ? education.label : ""
          clientList.education = education ? (education.label == "Other" ? clientList?.educationtxt : education.label) : "",
          
          setClient(clientList)
        }
        else if (response?.status?.code === 400) {
          alert(response?.status?.message);
        } else {
          console.error('Unhandled response:', response);
        }
      } catch (error) {
        console.error('Unhandled response:', error);
      }
    }

    useEffect(() => {
      clientData();
  }, [data['User ID']]);

    return (
      <Paper elevation={3} sx={{ padding: 2 }}>
        <h3 style={{ margin: 0, marginTop: 20 }}>Client</h3>
        <p style={{ margin: 2, fontWeight: 'bold' }}>{client.clientName}</p>
        <Box display="flex" alignItems="center" gap={2} sx={{ marginTop: 2 }}>
          <Grid 
            container 
            spacing={2} 
            sx={{ padding: 2, justifyContent: 'center' }} 
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={2} sm={4} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
              <TextField
                fullWidth
                label="Primary contact"
                value={client.primaryContact || ""}
                size="small"
                disabled
                variant="standard"
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
              <TextField
                fullWidth
                label="Alternate contact"
                value={client.alternateContact || ""}
                size="small"
                disabled
                variant="standard"
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
              <TextField
                fullWidth
                label="Age"
                value={client.age || ""}
                size="small"
                disabled
                variant="standard"
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
              <TextField
                fullWidth
                label="Gender"
                value={client.gender || ""}
                size="small"
                disabled
                variant="standard"
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
              <TextField
                fullWidth
                label="Location"
                value={client.location || ""}
                size="small"
                disabled
                variant="standard"
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
              <TextField
                fullWidth
                label="Education"
                value={client.education || ""}
                size="small"
                disabled
                variant="standard"
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
              <TextField
                fullWidth
                label="Occupation"
                value={client.occupation || ""}
                size="small"
                disabled
                variant="standard"
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
              <TextField
                fullWidth
                label="height (cm)"
                value={client.height || ""}
                size="small"
                disabled
                variant="standard"
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
              <TextField
                fullWidth
                label="Weight (Kg)"
                value={client.weight || ""}
                size="small"
                disabled
                variant="standard"
              />
            </Grid>
          </Grid>
        </Box>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              Additional Details
            </AccordionSummary>
            <AccordionDetails>
              <Box display="flex" alignItems="center" gap={2} sx={{ padding: 2 }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label="FaceWash"
                        value={client.faceWash || ""}
                        size="small"
                        disabled
                        variant="standard"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Moisturizer"
                      value={client.moisturizer || ""}
                      size="small"
                      disabled
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Sunscreen"
                      value={client.sunscreen || ""}
                      size="small"
                      disabled
                      variant="standard"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="serum"
                      value={client.serum || ""}
                      size="small"
                      disabled
                      variant="standard"
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box display="flex" alignItems="center" gap={2} sx={{ padding: 2 }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={6}>
                    <TextField
                      label="Source"
                      value={client.source || ""}
                      size="small"
                      disabled
                      variant="standard"
                    />
                  </Grid>
                </Grid>
              </Box>
            </AccordionDetails>
          </Accordion>
      </Paper>
      )
}

export default Client;