import React, { useState } from 'react';
import { Card, CardContent, Typography, IconButton, Tooltip, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WatchIcon from '@mui/icons-material/Watch';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { styled } from '@mui/system';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import { runSchedulerOnAnyTimeByCityId,applyRecommendedTherapistAndMachine } from '../../store/actions/center.action';
import CloseIcon from '@mui/icons-material/Close';


const CenterCard = ({ center, handleEdit, access }) => {
  const [expanded, setExpanded] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [loading,setLoading]=useState(false)
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationAction, setConfirmationAction] = useState('');
  const today = new Date();
  const maxDate = new Date();
  maxDate.setDate(today.getDate() + 90);
  const minDate = new Date();
  // minDate.setDate(today.getDate() + 1);
  const [selectedDate, setSelectedDate] = useState(minDate);

  const toggleExpand = () => {
    setExpanded((prevState) => !prevState);
  };

  const handleOpenDialog = () => setDialogOpen(true);
  const handleCloseDialog = () => setDialogOpen(false);

  // const handleOpenConfirmation = (action) => {
  //   setConfirmationAction(action); 
  //   setConfirmationOpen(true); 
  // };

  const handleOpenConfirmation = (action) => {
    setConfirmationAction(action); 
    setDialogOpen(false); 
    setConfirmationOpen(true);
  };
  

  const handleCloseConfirmation = () => {
    setConfirmationOpen(false);
    setConfirmationAction('');
  };

  const handleApplyRecommendation = async () => {
    setLoading(true)
    try {
      // Call the applyRecommendedTherapistAndMachine API
      const response = await applyRecommendedTherapistAndMachine({
        cityId: center['City Id'],
        date: selectedDate.toISOString().split('T')[0],
      });
      console.log("response-----", response)

      if (response?.status?.code === 200) {
        setSnackbarMessage('Recommended therapist and machine applied successfully for this center.');
      } else {
        console.error(response?.message || 'Failed to apply recommendations');
        alert('Failed to apply recommendations');
      }
      setSnackbarOpen(true);
    } catch (error) {
      alert('Some error occurred! Please try again later.');
    } finally {
      setLoading(false)
      handleCloseDialog();
    }
  };

  const handleLocationClick = (lat, long) => {
    window.open(`https://www.google.com/maps?q=${lat},${long}`, '_blank');
  };

  const FixedHeightCard = styled(Card)(({ theme }) => ({
    height: '350px',
    position: 'relative',
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
      // transform: 'translateY(-5px)',
      boxShadow: '0 3px 10px rgba(0, 0, 0, 0.2)',
    },
  }));

  const StyledAddress = styled(Typography)(({ expanded }) => ({
    // overflow: expanded ? 'visible' : 'hidden',
    // textOverflow: 'ellipsis',
    // whiteSpace: expanded ? 'normal' : 'nowrap',
    cursor: 'default',
    color: '#555',
  }));

  const handleRunScheduler = async () => {
    setLoading(false)
    try {
      const response = await runSchedulerOnAnyTimeByCityId({
        cityId: center['City Id'],
        date: selectedDate.toISOString().split('T')[0],
      });
      if (response?.status?.code === 200) {
        setSnackbarOpen(true);
        setSnackbarMessage('Scheduler runned successfully for this center.');
      } else {
        console.error('Failed to run scheduler:', response?.message || 'Unknown error');
        alert('Some error occurred ! please try again after some time');
      }
    } catch (error) {
      alert('Some error occurred ! please try again after some time');
    } finally {
      setLoading(false)
      handleCloseDialog();
    }
  };

  const handleConfirmationSubmit = () => {
    if (confirmationAction === 'run') {
      handleRunScheduler();
    } else if (confirmationAction === 'apply') {
      handleApplyRecommendation();
    }
    handleCloseConfirmation();
  };

  const handleCloseSnackbar = () => setSnackbarOpen(false);

  return (
    <>
    <Dialog open={confirmationOpen} onClose={handleCloseConfirmation}>
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to{' '}
            {confirmationAction === 'run'
              ? `run the scheduler for ${center['Center Name']}`
              : `apply recommendations for ${center['Center Name']}`} on{' '}
            {selectedDate.toISOString().split('T')[0]}?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmation} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleConfirmationSubmit} color="primary" variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    <FixedHeightCard
      sx={{
        backgroundColor: '#f5f5g6',
        borderRadius: '12px',
        boxShadow: '0 3px 10px rgba(0, 0, 0, 0.1)',
      }}
    >
      {access && <IconButton
        onClick={() => handleEdit(center)}
        sx={{
          position: 'absolute',
          top: 10,
          right: 10, 
          // backgroundColor: '#0077b6',
          color: 'gray',
          '&:hover': {
            // backgroundColor: '#005f8a',
            color: '#0077b6'
          },
        }}
      >

        <EditIcon />
      </IconButton>}

      <Card
        sx={{
          backgroundColor: '#f5f5f5',
          borderRadius: '12px',
          height: "100%",
          boxShadow: '0 3px 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        <CardContent>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6" gutterBottom sx={{ color: '#0077b6', fontWeight: 'bold', marginRight: '10px' }}>
              {center['Center Name']}
            </Typography>

            <div style={{ display: 'flex', gap: '10px' }}>
              <Tooltip title="Run Scheduler" arrow>
                <IconButton
                  onClick={() => { handleOpenDialog() }}
                  sx={{
                    backgroundColor: '#0077b6',
                    color: '#ffffff',
                    '&:hover': { backgroundColor: '#005f8a' },
                    borderRadius: '50%',
                    width: '40px',
                    height: '40px',
                  }}
                >
                  <WatchIcon />
                </IconButton>
              </Tooltip>

              {/* <Button
              variant="contained"
              color="primary"
              onClick={() => {handleOpenDialog()}}
              sx={{
                backgroundColor: '#0077b6',
                '&:hover': { backgroundColor: '#005f8a' },
              }}
            >
              Apply
            </Button> */}
            </div>
          </div>

          {/* Other center details */}
          <Typography variant="body1" gutterBottom>
            <strong>Center ID:</strong> {center['Id']}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Admin Name:</strong> {center['Admin Name']}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Admin Phone:</strong> {center['Admin Phone']}
          </Typography>

          {/* Address - Not Clickable */}
          <StyledAddress expanded={expanded} variant="body1" onClick={toggleExpand}>
            <strong>Address:</strong> {center['Address']}
          </StyledAddress>

          <Typography variant="body1" gutterBottom sx={{ marginTop: '5px' }}>
            <strong>Timings:</strong> {center['Start Time']} - {center['End Time']}
          </Typography>
          <Typography variant="body1" gutterBottom sx={{ marginTop: '5px' }}>
            <strong>Location:</strong> <small>{center['Lat']} - {center['Long']}</small>
            <IconButton
              onClick={() => handleLocationClick(center['Lat'], center['Long'])}
              sx={{ color: '#0077b6', marginBottom: '5px' }}
            >
              <LocationOnIcon />
            </IconButton>
          </Typography>

          {/* Dialog for date selection */}
          <Dialog open={dialogOpen} onClose={handleCloseDialog}>
            <DialogTitle>
              Select a Date
              <IconButton
                aria-label="close"
                onClick={handleCloseDialog}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <Typography variant="subtitle1" sx={{textAlign:'center',fontWeight:'bold' }}>
              {center['Center Name']}
            </Typography>
            <DialogContent>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label=""
                  value={selectedDate}
                  onChange={(newValue) => setSelectedDate(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  minDate={minDate} // Disable past dates
                  maxDate={maxDate} // Disable dates beyond 90 days
                  inputFormat="dd-MM-yyyy" // Display format
                />
              </LocalizationProvider>
            </DialogContent>
            <DialogActions>
              <Button
                // onClick={() => handleRunScheduler()}
                onClick={() => handleOpenConfirmation('run')}
                color="primary"
                variant="contained"
                disabled={!selectedDate || loading}
              >
               {loading?'Running...':'Run'}
              </Button>
              <Button
                onClick={() => handleOpenConfirmation('apply')}
                color="primary"
                variant="contained"
                disabled={!selectedDate || loading}
              >
                {loading?'applying':'Apply'}
              </Button>
            </DialogActions>
          </Dialog>
        </CardContent>

        {/* Snackbar for success message */}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Card>
    </FixedHeightCard>
    </>
  );
};

export default CenterCard;
