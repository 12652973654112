export const DROPDOWN_OPTIONS = [
    { value: "", label: "All" },
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
];

export const OCCUPATION_OPTIONS = [
    { id: "1", label: "Salaried" },
    { id: "2", label: "Self-Employed" },
    { id: "3", label: "Not Employed" },
    { id: "4", label: "Student" },
];

export const SOURCE_OPTIONS = [
    { id: "1", label: "IG" },
    { id: "2", label: "FB" },
    { id: "3", label: "Google" },
    { id: "4", label: "Offline channel" },
    { id: "5", label: "Friends & Family" },
    { id: "6", label: "Others" },
]

export const GENDER_OPTIONS = [
    {id: "male", label: "Male"},
    {id: "female", label: "Female"},
    {id: "other", label: "Other"}
]

export const EDUCATION_OPTIONS = [
    {id: "1", label: "High School (or equivalent)"},
    {id: "2", label: "Graduate / Bachelor's degree"},
    {id: "3", label: "Post Graduate / Master's degree"},
    {id: "4", label: "Other"}
]


export const DOCTOR_SPECIALIY = [
    {id: "DERMATOLOGIST", label: "DERMATOLOGIST"},
    {id: "DIETICIAN", label: "DIETICIAN"}
]

export const QUALIFICATION = [
    {id: "MBBS", label: "MBBS"},
    {id: "MD", label: "MD"},
    {id: "DIPLOMA", label: "DIPLOMA"},
    {id: "OTHERS", label: "OTHERS"}
]

export const PAY_OPTION = [
    {id: "FIXED", label: "FIXED"},
    {id: "PER_CONSULTATION_BASIS", label: "PER_CONSULTATION_BASIS"}
]

export const PRODUCT_CATEGORY = [
    {id: "FACE_WASH & CLEANSERS", label: "FACE_WASH & CLEANSERS"},
    {id: "MOISTURISERS", label: "MOISTURISERS"},
    {id: "ALL SUNSCREENS", label: "ALL SUNSCREENS"},
    {id: "DE-PIGMENTING AGENTS", label: "DE-PIGMENTING AGENTS"},
    {id: "EXFOLIANTS", label: "EXFOLIANTS"},
    {id: "VITAMIN C SERUMS", label: "VITAMIN C SERUMS"},
    {id: "NIACINAMIDE", label: "NIACINAMIDE"},
    {id: "SUPPLEMENTS", label: "SUPPLEMENTS"},
    {id: "UNDER EYE CREAMS", label: "UNDER EYE CREAMS"},
    {id: "LIP CARE", label: "LIP CARE"},
    {id: "BODY WASH",label: "BODY WASH"},
    {id: "FACE MASK", label: "FACE MASK"},
    {id: "SOAPS", label: "SOAPS"},
    {id: "ANTI ALLERGIC", label: "ANTI ALLERGIC"},
    {id: "ANTI ACNE",label: "ANTI ACNE"},
    {id: "STEROIDS", label: "STEROIDS"},
    {id: "SHAMPOOS", label: "SHAMPOOS"},
    {id: "CONDITIONER", label: "CONDITIONER"},
    {id: "HAIR MASKS", label: "HAIR LOSS SERUMS"}
]
