import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Form from "./Form/Form";
import { getZohoAppointmentById } from "../../../store/actions/zohoAppointment.action";

const AppointmentDetails = () =>{
    const navigate = useNavigate();
    const { id } = useParams();
    const [doctorData, setdoctorData] = useState();
    const [appointment, setAppointment] = useState();
    
    const getAppointmentDetails = async()=>{      
      try {
        const {status, data} = await getZohoAppointmentById(id)
            
        if(status.code == 200){          
          setAppointment(data[0])
        }      
        else{
          window.alert(status.message)
          navigate('/doctor/appointment');
        }           
      } catch (error) {      
          navigate('/doctor/appointment');
          window.alert("Something went wrong")
      }
    }

    useEffect(()=>{
        const localDoctorData = JSON.parse(localStorage.getItem("doctorData"))
    
        if(localDoctorData?.status?.code !== 200){
          navigate('/doctor/login');  
        }
        else{
          setdoctorData(localDoctorData.doctor)
        }
    },[id])

    useEffect(() => {    
      getAppointmentDetails();        
    }, [id])

    return (
      <>
        {
          appointment && <Form doctor={doctorData} appointment={appointment}/>
        }
      </>
    )
}

export default AppointmentDetails