import React from 'react';
import {
  Box,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';

const CustomComponent = ({ index, data, onChange }) => {
  const handleTextChange = (e) => {
    onChange(index, 'question', e.target.value);
  };

  const handleTypeChange = (e) => {
    onChange(index, 'type', e.target.value);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 3,
      }}
    >
      <TextField
        label="Enter Question"
        variant="outlined"
        value={data.question}
        onChange={handleTextChange}
        fullWidth
      />

      <FormControl fullWidth>
        <InputLabel id={`dropdown-label-${index}`}>Select Option</InputLabel>
        <Select
          labelId={`dropdown-label-${index}`}
          value={data.type}
          onChange={handleTypeChange}
          label="Select Option"
        >
          <MenuItem value="radio">Radio (Yes/No)</MenuItem>
          <MenuItem value="scale">Rating</MenuItem>
          <MenuItem value="input">Input Field</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default CustomComponent;
