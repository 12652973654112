import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Box, Snackbar, Alert } from '@mui/material';
import axios from 'axios';

const LockToggleButton = ({ sessionScheduleId, adminUserId, isLocked }) => {
  
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  

  const handleLockToggle = async () => {
    try {
      setIsButtonDisabled(true);
      const response = await axios.patch(
        `${process.env.REACT_APP_BACKEND_API_URL}/orders/lock`,
        {
          adminId: adminUserId,
          sessionScheduleId,
        }
      );

      if (response?.status === 200){// Toggle the state on success
        setSnackbarOpen(true);
        setSnackbarMessage(isLocked ? 'This booking is unlocked successfully' : "This booking is locked successfully");
        setTimeout(() => {
          window.location.reload();
        },1000);
      } else {
        alert(response?.data?.status?.message || 'An error occurred while toggling.');
      }
    } catch (error) {
      console.error('Error toggling lock status:', error);
      alert('Failed to toggle lock status. Please try again.');
    } finally {
      setIsButtonDisabled(false);
    }
  };


  const handleCloseSnackbar = () => setSnackbarOpen(false);

  return (
    <>
    <Box display="flex" justifyContent="flex-end" mt={2}>
      <Button
        variant="contained"
        color={isLocked ? 'secondary' : 'primary'} 
        onClick={handleLockToggle}
        style={{ textTransform: 'none' }}
      >
        {isButtonDisabled ? (
          <CircularProgress size={24} color="inherit" />
        ) : isLocked ? (
          'Unlock'
        ) : (
          'Lock'
        )}
      </Button>
    </Box>

    <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      </>
  );
};

export default LockToggleButton;
