import { Button, Box, Grid, IconButton, Paper, TextField, Typography, MenuItem } from "@mui/material"
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { hasAdminAccess } from "../../../components/common/UserRolesConfig";

import { DOCTOR_SPECIALIY, QUALIFICATION, PAY_OPTION } from "../../../constants/index";
import { getDoctorById, updateDoctorById } from "../../../store/actions/doctor.action";

const ViewDoctor = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const role = JSON.parse(localStorage.getItem('userData'))?.user?.role;
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [formData, setFormData] = useState({
        doctorName: "",
        contactNumber: "",
        alternateNumber: "",
        emailId: "",
        city: "",
        speciality: "",
        qualification: "",
        qualificationDescription: "",
        payOption: "",
        experience: "",
        registration: ""
    })

    const handleChange = (e) => {
        const { name, value } = e.target;

        let updatedFormData = { ...formData };
        updatedFormData = {
            ...updatedFormData,
            [name]: value,
        };
        setFormData(updatedFormData);
    };

    const getDoctor = async () => {
        const res = await getDoctorById(id);
        if (res.status.code == 200 && res.doctor) {
            const doctor = res.doctor;
            setFormData((prevState) => ({
                ...prevState,
                "doctorName": doctor.doctorName,
                "contactNumber": doctor.contactNumber,
                "alternateNumber": doctor.alternateNumber,
                "emailId": doctor.emailId,
                "city": doctor.city,
                "speciality": doctor.speciality,
                "qualification": doctor.qualification,
                "qualificationDescription": doctor.qualificationDescription,
                "payOption": doctor.payOption,
                "experience": doctor.experience,
                "registration": doctor.registration
            }));
        } else if (res?.status?.code === 400) {
            alert(res?.status?.message);
        } else {
            alert('Unhandled response:', res);
        }
    }

    useEffect(() => {
        try {
            getDoctor()
        } catch (error) {
            alert('An error occurred. Please try again later.');
        }
    }, [id])

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            const res = await updateDoctorById(id, formData)
            if (res?.status?.code == 200) {
                alert(res?.status?.message);
                navigate('/doctor');
            } else if (res?.status?.code === 400) {
                alert(res?.status?.message);
            } else {
                alert('Unhandled response:', res);
            }
            console.log(res, 12356);

        } catch (error) {
            alert('An error occurred. Please try again later.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Paper elevation={3}
            sx={{
                padding: 4, margin: 'auto', maxWidth: 800, marginTop: 2, borderRadius: 2
            }}>
            <Box sx={{ position: 'relative' }}>
                <IconButton
                    color='primary'
                    onClick={() => navigate('/doctor')}
                    sx={{ position: 'absolute', top: 0, left: 0 }}>
                    <KeyboardBackspaceIcon />
                </IconButton>
                <Typography variant="h4"
                    align="center"
                    gutterBottom sx={{ color: '#0077b6', fontWeight: 'bold' }}
                >
                    Update Doctor
                </Typography>
            </Box>

            <Box component="form" marginTop={5} onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <FromField
                        formData={formData}
                        handleChange={handleChange}
                    />

                    {(hasAdminAccess(role)) && (
                        <Grid item xs={12}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={isSubmitting}
                                sx={{ padding: '12px', fontWeight: 'bold', fontSize: '16px' }}
                            >
                                {
                                    isSubmitting ? 'Updating' : 'Update'
                                }
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </Paper>
    )
}
export default ViewDoctor

const FromField = ({ formData, handleChange }) => {
    return (
        <>
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="doctorName"
                    name="doctorName"
                    value={formData.doctorName}
                    onChange={handleChange}
                    required
                    size="small"
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="contact number"
                    name="contactNumber"
                    type="tel"
                    value={formData.contactNumber}
                    onChange={handleChange}
                    inputProps={{ pattern: '[0-9]{10}', title: 'Please enter a 10-digit phone number' }}
                    required
                    size="small"
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="alternate number"
                    name="alternateNumber"
                    type="tel"
                    value={formData.alternateNumber}
                    onChange={handleChange}
                    inputProps={{ pattern: '[0-9]{10}', title: 'Please enter a 10-digit phone number' }}
                    size="small"
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="email"
                    name="emailId"
                    value={formData.emailId}
                    onChange={handleChange}
                    required
                    size="small"
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="city"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    required
                    size="small"
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    select
                    label="speciality"
                    name="speciality"
                    size="small"
                    onChange={handleChange}
                    value={formData.speciality}
                >
                    {DOCTOR_SPECIALIY.map((speciality) => (
                        <MenuItem key={speciality.id} value={speciality.id}>
                            {speciality.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    select
                    label="qualification"
                    name="qualification"
                    size="small"
                    onChange={handleChange}
                    value={formData.qualification}
                >
                    {QUALIFICATION.map((qualification) => (
                        <MenuItem key={qualification.id} value={qualification.id}>
                            {qualification.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="qualification description"
                    name="qualificationDescription"
                    value={formData.qualificationDescription}
                    onChange={handleChange}
                    required
                    size="small"
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    select
                    label="payOption"
                    name="payOption"
                    size="small"
                    onChange={handleChange}
                    value={formData.payOption}
                >
                    {PAY_OPTION.map((payOption) => (
                        <MenuItem key={payOption.id} value={payOption.id}>
                            {payOption.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="experience"
                    name="experience"
                    value={formData.experience}
                    onChange={handleChange}
                    required
                    size="small"
                    type="number"
                    inputProps={{
                        min: 1
                    }}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="Registration No"
                    name="registration"
                    value={formData.registration}
                    onChange={handleChange}
                    required
                    size="small"
                />
            </Grid>
        </>
    )
}