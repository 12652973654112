import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import LogIn from './pages/Login/LogIn';
import Layout from './components/layout/Layout';
import Profile from './pages/Profile/Profile';
import { routes } from './routes';
import Coupons from './pages/Coupons/Coupons';
import Packages from './pages/Packages/Packages';
import AdvancePayments from './pages/AdvancePayment/AdvancePayments';
import AddAdvancePayments from './pages/AdvancePayment/AddAdvancePayments';
import UserList from './pages/Users/UserList';
import FAQ from './pages/FAQ/FAQ';
import ViewProductDetails from './pages/Product/ViewProductDetails';
import AddProductStepForm from './pages/Product/AddProductStepForm';
import { ViewCheckedIncentive } from './pages/Incentive/ViewCheckedIncentive';
import ViewUncheckedIncentive from './pages/Incentive/ViewUncheckedIncentive';
import BookingDetails from './pages/BookingDetails/BookingDetails';
import AddCoupon from './pages/Coupons/AddCoupon';
import VirtualConsultations from './pages/VirtualConsultations/VirtualConsultations';
import PrivateRoute from './PrivateRoute';
import AddUser from './pages/Users/AddUser';
import AddFAQ from './pages/FAQ/AddFAQ';
import AddPayment from './components/common/BookingComponent/AddPayment';
import AddEditMachineForm from './pages/Machine/AddEditMachineForm';
import AddEditPartnerForm from './pages/Partner/AddEditPartnerForm';
import AddEditCenterForm from './pages/Center/AddEditCenterForm';
import AddEditTherapistForm from './pages/Therapist/AddEditTherapistForm';
import AddMachineRequest from './pages/Machine/AddMachineRequest';
import AddTherapistUnavailability from './pages/Request/AddTherapistUnavailability.jsx';
import PackagesSteps from './pages/Packages/PackageStep/PackageSteps';
import TransactionHistory from './pages/AdvancePayment/TransactionHistory.jsx';
import Deallocation from './pages/TechDeallocation/Deallocation.jsx';
import DuplicateBlockedId from './pages/TechDeallocation/DuplicateBlockedId.js';
import ViewMachine from './pages/Machine/ViewMachine.jsx';
import ViewTherapist from './pages/Therapist/ViewTherapist.jsx';
import AddTherapist from './components/common/Availability/AddTherapist.jsx';
import AddMachine from './components/common/Availability/AddMachine.jsx';
import SlotsInfoPage from './pages/ClientSlots/ClientSlots'; // Import SlotsInfoPage
import SlotTable from './pages/ClientSlots/ClientSlotOps.jsx';
import ClientList from './pages/Clients/ClientList.jsx';
import ClientDetails from './pages/Clients/ClientDetails.jsx';
import { hasPackageAccess } from './utils/access.js';
import AddProduct from './pages/Products/AddProduct';
import EditMachine from './pages/Machine/EditMachine.jsx';
import SignIn from './pages/Login/SignIn.jsx';
import Appointments from './pages/Doctor/Appointments/Appointments.jsx';
import AppointmentDetails from "./pages/Doctor/Appointments/AppointmentDetails.jsx";
import ViewDoctor from './pages/Doctor/Doctor/ViewDoctor.jsx';
import ViewMedications from './pages/Doctor/Medication/ViewMedication.jsx';
import AddDoctor from './pages/Doctor/Doctor/AddDoctor.jsx';
import AddMedication from './pages/Doctor/Medication/AddMedication.jsx';
import AddServiceQuestion from './pages/ServiceQuestions/AddServiceQuestion';
// import { setEnvironemt } from './env.js';

function App() {
  const [hasCallerAccess, setHasCallerAccess] = useState(false);
  // setEnvironemt();

  useEffect(() => {
    // Retrieve user data from localStorage
    const stringifiedUser = localStorage.getItem('userData');
    const userData = stringifiedUser ? JSON.parse(stringifiedUser) : null;

    // Check if the user has caller access and set state
    if (userData && userData.user && userData.user.callerAccess) {
      setHasCallerAccess(true);
    } else {
      setHasCallerAccess(false);
    }
  }, []); // Only run this effect on the first render

  return (
    <div>
      <Router>
        <Routes>
          <Route exact path="/" element={<LogIn />} />
          <Route element={<PrivateRoute Component={Layout} />}>
            {/* Conditionally render routes based on caller access */}
            {hasCallerAccess ? (
              // Show only /slots-info route if the user has callerAccess
              <>
                <Route path="/slots-info" element={<SlotsInfoPage />} />
                <Route path="/slots-ops" element={<SlotTable />} />
              </>

            ) : (
              // Render all other routes if the user does not have callerAccess
              <>
                {routes}
                <Route path="/profile" element={<Profile />} />
                <Route
                  path="/viewproductdetails"
                  element={<ViewProductDetails />}
                />
                <Route path="/coupons" element={<Coupons />} />
                <Route path="/users" element={<UserList />} />
                <Route
                  path="/addproductstep"
                  element={<AddProductStepForm />}
                />
                <Route
                  path="/viewcheckedincentive"
                  element={<ViewCheckedIncentive />}
                />
                <Route
                  path="/viewuncheckedincentive"
                  element={<ViewUncheckedIncentive />}
                />
                <Route
                  path="/booking-details/:sessionScheduleId"
                  element={<BookingDetails />}
                />
                <Route path="/add-coupon" element={<AddCoupon />} />
                <Route
                  path="/packages/packagesteps"
                  element={<PackagesSteps />}
                />
                <Route
                  path="/service-questions/addquestions"
                  element={<AddServiceQuestion />}
                />
        
                 <Route
                  path="/skincare-products/add-product"
                  element={<AddProduct />}
                />
                <Route
                  path="/virtual-consultations"
                  element={<VirtualConsultations />}
                />
                <Route path="/user/add-user" element={<AddUser />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/add-faq" element={<AddFAQ />} />
                <Route path="/add-booking-payment" element={<AddPayment />} />
                <Route path="/addedit-center" element={<AddEditCenterForm />} />
                <Route
                  path="/addedit-machine"
                  element={<AddEditMachineForm />}
                />
                <Route
                  path="/addedit-partner"
                  element={<AddEditPartnerForm />}
                />
                <Route
                  path="/addedit-therapist"
                  element={<AddEditTherapistForm />}
                />
                <Route
                  path="/add-machine-request"
                  element={<AddMachineRequest />}
                />
                {hasPackageAccess() && <Route path="/packages" element={<Packages />} />}
                <Route
                  path="/addtherapist-unavailability"
                  element={<AddTherapistUnavailability />}
                />
                <Route path="/advance-payments" element={<AdvancePayments />} />
                <Route
                  path="/add-advance-payments"
                  element={<AddAdvancePayments />}
                />
                <Route
                  path="/transaction-history/:id"
                  element={<TransactionHistory />}
                />
                <Route
                  path="/therapist-deallocation"
                  element={<Deallocation />}
                />
                <Route
                  path="/duplicate-blocked-id"
                  element={<DuplicateBlockedId />}
                />
                <Route
                  path="/view-machine/:machineId"
                  element={<ViewMachine />}
                />
                <Route
                  path="/view-therapist/:therapistId"
                  element={<ViewTherapist />}
                />
                <Route path="/add-therapist" element={<AddTherapist />} />
                <Route path="/add-machine" element={<AddMachine />} />
                <Route path="/clients" element={<ClientList />}/>
                <Route path="/clientdetails" element={<ClientDetails/>} />
                <Route path='/doctor/view-doctor/:id' element={<ViewDoctor/>}/>
                <Route path='/medications/view-medication/:id' element={<ViewMedications/>}/>
                <Route path="/doctor/add-doctor" element={<AddDoctor/>}/>
                <Route path="/medications/add-medication" element={<AddMedication/>}/>
              </>
            )}
            <Route path="/edit-machine" element={<EditMachine/>}/>
          </Route>
          <Route path="/doctor/login" element={<SignIn/>}/>
          <Route path='/doctor/appointment' element={<Appointments/>}/>
          <Route path='/doctor/appointment-details/:id' element={<AppointmentDetails/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
