import Header from "./Header"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { getDoctorAppointment } from "../../../store/actions/zohoAppointment.action";
import { Stack, FormControl, InputLabel, Select, MenuItem, 
  TextField, IconButton, Pagination 
 } from "@mui/material";
import { Search } from "@mui/icons-material"; 
import TableComponent from "../../../components/common/TableComponent/TableComponent";
import LoaderComponent from "../../../components/common/LoaderComponent/LoaderComponent";

const Appointments = ()=>{
  const navigate = useNavigate();
  const limit = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [zohoAppointment, setZohoAppointment] = useState({
    appointmentList: [],
    totalAppointment: 0,
    isLoading: false,
    totalPages: 1
  })

  const [search, setSearch] = useState({
    type: "",
    text: "",
    searchClick: false
  })

  useEffect(()=>{
    const doctorData = JSON.parse(localStorage.getItem("doctorData"))

    if(doctorData?.status?.code === 200){
      navigate('/doctor/appointment');  
    }else{
      navigate('/doctor/login');  
    }   
  },[])

  const handlePageChange = (event, value) => {
    if (value >= 1 && value <= zohoAppointment.totalPages) {
      setCurrentPage(value);
    }
  };

  const getAppointmentDetails = async()=>{
    setZohoAppointment((prevState)=>({
      ...prevState,
      isLoading: true
    }))
    
    try {
      const response = await getDoctorAppointment(limit, currentPage, search.type, search.text)
    
      setZohoAppointment((prevState) => ({
        ...prevState,
        appointmentList: response.list,
        totalAppointment: response.total,
        totalPages: Math.ceil(response.total/limit),
        isLoading: false,
    }));
        
    } catch (error) {      
      setZohoAppointment((prevState)=>({
        ...prevState,
        isLoading: false
      }))
    }
  }
    
  const handleViewDetails=(data)=>{
    if(data){
      navigate(`/doctor/appointment-details/${data?.id}`, {state: data})
    }
  }
  useEffect(() => {    
    const debounceTimer = setTimeout(() => {
      getAppointmentDetails();
    }, 500);
          
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [currentPage, search.searchClick, search.text])

    return (
        <>
          <Header/>
          <div style={{marginLeft: "50px", marginRight: "50px"}}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: "100px" }}>
              <h3>Appointment</h3>
            </div>
            <SearchComponent                     
              search={search}
              setSearch={setSearch}
            />
            <Stack direction="row" spacing={2} mb={3} style={{alignItems: 'center', justifyContent: 'space-between'}}>
              <div>
                  Total Consultations: {zohoAppointment.totalAppointment}
              </div>
            </Stack>

            {
              zohoAppointment?.isLoading ? 
              (
                <LoaderComponent/>
              ):
              (
                <TableComponent
                  data={zohoAppointment.appointmentList}
                  hiddenFields={["id"]}
                  viewButton={'view'}
                  viewDetails={handleViewDetails}
                />
              )
            }
            <div className="incentiv-pagination" style={{ marginTop: '1rem' }}>
              <Stack spacing={3}>
                <Pagination
                  count={zohoAppointment.totalPages} page={currentPage} onChange={handlePageChange}
                  color="primary"
                />
              </Stack>
            </div>
          </div>
        </>
    )
}

export default Appointments

const SearchComponent = ({
  search,
  setSearch,
}) => {  

  const handleSearch = () => {
      if ((search.text.trim()).length < 1) {
          return;
      }
      setSearch((prevState) =>({
          ...prevState,
          "searchClick": !prevState.searchClick
      }))
  };

  const handleChange = (event) => {
      const type = event.target.value;
      setSearch((prevState) =>({
          ...prevState,
          "text": "",
          "type": type
      }))
  };

  const handleSearchOnClick = (e) => {
      if ((search.text.trim()).length < 1) {
          return;
      }
      if (e.key === 'Enter') {
          e.preventDefault();  
          setSearch((prevState) =>({
              ...prevState,
              "searchClick": !prevState.searchClick
          }))
      }
  };

  const handleTextChange = (e) => {
      const text = e.target.value
      setSearch((prevState) =>({
          ...prevState,
          "text": text
      }))
  }

  return (
    <>
      <FormControl sx={{ minWidth: 200, marginBottom: 2, marginRight: 1 }} size="small">
        <InputLabel id="demo-simple-select-standard-label">
          Filter Type
        </InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={search.type}
          label="searchType"
          onChange={handleChange}
        >
          <MenuItem value="bookingId">Booking Id</MenuItem>
          <MenuItem value="customerName">Name</MenuItem>
          <MenuItem value="serviceName">Service Name</MenuItem>
          <MenuItem value="status">Status</MenuItem>
        </Select>
      </FormControl>

      <TextField
        placeholder="Search"
        variant="outlined"
        size="small"
        value={search.text}
        type={["customer_contact_no", "staff_contact_number"].includes(search.type) ? "number" : "text"}
        required={true}
        onChange={handleTextChange}
        onKeyDown={handleSearchOnClick}
        InputProps={{
          endAdornment: (
            <IconButton color="primary" onClick={handleSearch}>
              <Search />
            </IconButton>
          ),
        }}
        sx={{marginBottom: 2}}
      />
    </>
  );
};