
import { Add, Remove } from "@material-ui/icons"
import { Autocomplete, Grid, TextField} from "@mui/material"
import React, { useEffect, useState } from "react";
import { getMedication, getProduct, getProductSession } from "../../../../store/actions/zohoAppointment.action";

const PrescriptionForm = ({formData, handleChange, setFormData})=>{
    const [medicineList, setMedicineList] = useState([])
    const [productList, setProductList] = useState([]);
    const [productSessionLists, setProductSessionLists] = useState({})
    const getMedicationDetails = async()=>{
        try 
        {
            const {list} = await getMedication()
            setMedicineList(list)         
        } catch (error) {
        }
    }

    const getProductList = async () =>{
        try {
            const gender = JSON.parse(formData?.customerInfo)?.["Gender"][0]            
            const { list } = await getProduct(gender);            
            setProductList(list)
        } catch (error) {            
        }
    }
    const getProductSessionList = async (id) =>{        
        try {            
            if(!productSessionLists[id]){                
                const {list} = await getProductSession(id)              
                setProductSessionLists((prev) => ({
                    ...prev,
                    [id]: list,
                })); 
            }       
        } catch (error) {            
        }
    }

    useEffect(()=>{
        getMedicationDetails();   
        getProductList();
    }, [])

    const addMedicine = () => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          medicines: [
            ...prevFormData.medicines,
            { name: "", quantity: "", frequency: "", instruction: "" },
          ],
        }));
    };
    const addService = () => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          services: [
            ...prevFormData.services,
            { service: "", numberOfSessions: "" },
          ],
        }));
    };

    const removeMedicine = (index) => {
        setFormData((prevFormData) => {
          const updatedMedicines = prevFormData.medicines.filter((_, i) => i !== index);
          return { ...prevFormData, medicines: updatedMedicines };
        });
    };
    const removeService = (index) => {
        setFormData((prevFormData) => {
          const updatedServices = prevFormData.services.filter((_, i) => i !== index);
          return { ...prevFormData, services: updatedServices };
        });
    };

    const handleMedicineChange = (index, field, value) => {        
        setFormData((prevFormData) => {
          const updatedMedicines = [...prevFormData.medicines];
          updatedMedicines[index] = { ...updatedMedicines[index], [field]: value };
          return { ...prevFormData, medicines: updatedMedicines };
          });
    };
    const handleServiceChange = (index, field, value) => {        
        setFormData((prevFormData) => {
          const updatedServices = [...prevFormData.services];
          updatedServices[index] = { ...updatedServices[index], [field]: value };
          return { ...prevFormData, services: updatedServices };
        });

        if(field == "productId" && value){
            getProductSessionList(value)       
        }
    };

    return (
        <>
            <Grid item xs={12} md={12} style={{display: "flex", justifyContent: "center"}}>
                <h2>
                    SkinRx by Avataar
                </h2>
            </Grid>

            <Grid item xs={12} md={12}>
                <h4>
                    Doctor: {formData.doctorName}
                </h4>
                <h4>
                    Reg No: {formData.registration}
                </h4>
                <h4>
                    Patient: {formData.patientName}
                </h4>
                <h4>
                    Age: {JSON.parse(formData?.customerInfo)?.["Your Age"]}
                </h4>
                <h4>
                    Gender: {JSON.parse(formData?.customerInfo)?.["Gender"]}
                </h4>
                <h4>
                    Prescription Id: {formData.bookingId}
                </h4>
                <h4>
                    Service: {formData.serviceName}
                </h4>
                <h4>
                    Date: {formData.todayDate}
                </h4>
            </Grid>
        
            <Grid item xs={12} md={12}>
                <TextField
                    required
                    fullWidth 
                    multiline 
                    rows={2} 
                    label="Complaint"   
                    name='complaint'
                    size="small"  
                    onChange={handleChange} 
                    value={formData.complaint}
                />
            </Grid>
            <Grid item xs={12} md={12}>
                <TextField
                    required
                    fullWidth 
                    multiline 
                    rows={2} 
                    label="Diagnosis"   
                    name='diagnosis'
                    size="small"  
                    onChange={handleChange} 
                    value={formData.diagnosis}
                />
            </Grid>

            <Grid item xs={12} style={{display: "flex", alignItems: "center"}}>
                <p>Medicines</p>
                <Add fontSize="small" onClick={addMedicine}/>
            </Grid>

            {
                medicineList.length > 0 && formData.medicines.map((medicine, index)=>(
                    <React.Fragment key={index}>
                        <Grid item xs={12} md={6} style={{display: "flex", alignItems: "center"}}>
                            <Remove onClick={(e)=>{e.preventDefault(); removeMedicine(index)}}/>
                            <Autocomplete
                                fullWidth
                                required
                                size="small"
                                disablePortal
                                id="combo-box-demo"
                                options={medicineList}
                                value={
                                    medicine.name 
                                }
                                onChange={(e, newValue) => {
                                    handleMedicineChange(index, "name", newValue ? newValue.label : "");
                                    handleMedicineChange(index, "productId", newValue ? newValue.productId : "");
                                    handleMedicineChange(index, "quantity", "")
                                    handleMedicineChange(index, "frequency", "")
                                }}
                                renderInput={(params) => <TextField variant="outlined" {...params} label="Products" />}
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <TextField
                                fullWidth
                                type="number"
                                label="Quanity"
                                name="quantity"
                                InputProps={{ 
                                    inputProps: 
                                    { min: 1, max: medicineList.find((option) => option.label === medicine.name)?.quantity || 1, } 
                                }}
                                inputProps={{
                                    min: 1
                                }}
                                value={medicine.quantity}
                                onChange={(e) => 
                                    handleMedicineChange(index, "quantity", e.target.value)
                                }
                                required
                                size="small"
                            />
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <TextField
                                fullWidth
                                type="number"
                                label="Frequency"
                                name="frequency"
                                value={medicine.frequency}
                                onChange={(e) => handleMedicineChange(index, "frequency", e.target.value)}
                                required
                                size="small"
                                inputProps={{
                                    min: 1
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                type="text"
                                multiline
                                rows={2}
                                label="Instruction"
                                name="instruction"
                                value={medicine.instruction}
                                onChange={(e) => handleMedicineChange(index, "instruction", e.target.value)}
                                required
                                size="small"
                            />
                        </Grid>
                    </React.Fragment>
                ))
            }

            <Grid item xs={12} style={{display: "flex", alignItems: "center"}}>
                <p>Services</p>
                <Add fontSize="small" onClick={addService}/>
            </Grid>

            {
                formData.services.map((service, index)=>(
                    <React.Fragment key={index}>
                        <Grid item xs={12} md={6} style={{display: "flex", alignItems: "center"}}>
                            <Remove onClick={(e)=>{e.preventDefault(); removeService(index)}}/>
                            
                            <Autocomplete
                                fullWidth
                                required
                                size="small"
                                disablePortal
                                id="combo-box-demo"
                                options={productList}
                                value={ 
                                    service.name
                                }
                                onChange={(e, newValue) => { 
                                    handleServiceChange(index, "productId", newValue ? newValue.productId : "");
                                    handleServiceChange(index, "name", newValue ? newValue.label : "")
                                    handleServiceChange(index, "productSessionId", "")
                                    handleServiceChange(index, "numberOfSessions", "")
                                }}
                                renderInput={(params) => <TextField variant="outlined" {...params} label="Services" />}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Autocomplete
                                fullWidth
                                required
                                size="small"
                                disablePortal
                                id="combo-box-demo"
                                getOptionLabel={(option) => (option.count ? option.count.toString() : '')}
                                options={    
                                    productSessionLists[service.productId] ?? []
                                }
                                value={
                                    service.numberOfSessions && productSessionLists[service.productId] && productSessionLists[service.productId].length
                                    ? productSessionLists[service.productId].find((option) => option.count === service.numberOfSessions)
                                    : null
                                }
                                onChange={(e, newValue) => {
                                    handleServiceChange(index, "productSessionId", newValue ? newValue.productSessionId : "");
                                    handleServiceChange(index, "numberOfSessions", newValue ? newValue.count : "")
                                }}
                                renderInput={(params) => <TextField variant="outlined" {...params} label="number of sessions" />}
                            />
                        </Grid>
                    </React.Fragment>
                ))
            }

            <Grid item xs={12} md={12}>
                <TextField
                    required
                    fullWidth 
                    multiline 
                    rows={2} 
                    label="Notes"   
                    name='notes'
                    size="small"  
                    onChange={handleChange} 
                    value={formData.notes}
                />
            </Grid>
        </>
    )
}


export default PrescriptionForm