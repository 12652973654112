import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getToken } from '../../components/common/userLocalStorageUtils';
import axios from 'axios';

const ServiceQuestions = () => {
  const navigate = useNavigate();
  const [allCategoriesQuestions, setAllQuestionsCategories] = useState();
  const allQuestions = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/admin/client/get-all-category-questions`,
        {
          headers: {
            Authorization: `Basic ${process.env.REACT_APP_ADMIN_APP_KEY}`,
            token: getToken(),
          },
        },
      );

      setAllQuestionsCategories(res.data.list);
    } catch (e) {
      console.error('Error fetching data:', e);
    }
  };

  const deleteCategory = async (categoryId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/v1/admin/client/remove-category-questions?categoryId=${categoryId}`,
        {
          headers: {
            Authorization: `Basic ${process.env.REACT_APP_ADMIN_APP_KEY}`,
            token: getToken(),
          },
        },
      );

      setAllQuestionsCategories((prevCategories) =>
        prevCategories.filter((category) => category.categoryId !== categoryId),
      );
      alert('Category deleted successfully');
    } catch (e) {
      console.error('Error deleting category:', e);
      alert('Failed to delete category');
    }
  };
  useEffect(() => {
    allQuestions();
  }, []);

  return (
    <div>
      <Typography variant="h4" align="center" gutterBottom>
        Service Questions
      </Typography>
      <Button
        concentrixUservariant="contained"
        color="primary"
        style={{
          margin: '10px',
          backgroundColor: '#384456',
          color: 'white',
          transition: 'transform 0.3s,background-color 0.3s',
        }}
        onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
        onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}
        onClick={() => {
          navigate('/service-questions/addquestions');
          localStorage.setItem('serviceQuestion', 'add');
        }}
      >
        Add Questions
      </Button>
      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Category ID</strong>
              </TableCell>
              <TableCell>
                <strong>Category Name</strong>
              </TableCell>
              <TableCell>
                <strong>Gender</strong>
              </TableCell>
              <TableCell>
                <strong>Questions</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allCategoriesQuestions?.map((category, index) => (
              <TableRow key={index}>
                <TableCell>{category.categoryId}</TableCell>
                <TableCell>{category.categoryName}</TableCell>
                <TableCell>{category.gender}</TableCell>
                <TableCell>
                  {(category.questions).map((q, i) => (
                    <div>
                      <strong>Q{i + 1}: </strong>
                      {q.question} - <strong>Type: </strong>{q.type === 'scale' ? 'rating' : q.type}
                    </div>
                  ))}
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => deleteCategory(category.categoryId)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ServiceQuestions;
